export const base = {
  "src.components.routes.pages.Root.title": "NutritionPro",
  "src.components.routes.pages.BackBoxes.title": "NutritionPro",

  "error.date.required": "Date is required",
  "error.paymentMethod.required": "Payment method is required.",
  "src.components.atoms.PricePerDay.title": "Price per menu",
  "src.components.atoms.PricePerDay.description": "(excluding delivery)",
  "src.components.atoms.PricePerDay.from": "from",
  "src.components.atoms.PricePerDay.symbol": "{price} CZK",
  "src.components.molecules.PriceCard,symbol": "{price} CZK",
  "src.components.routes.pages.DietBrno.title": "Brno",
  "src.components.molecules.PriceCard.title": "Order price",
  "src.components.molecules.PriceCard.delete": "Remove",
  "src.components.molecules.PriceCard.btn": "Continue",
  "error.companyName.required": "Enter company name",
  "error.companyAddress.required": "Enter billing address",
  "error.regNumber.required": "Enter company registration number",
  "error.vatNumber.required": "Enter company VAT number",
  "error.polygon.required": "City for your address",
  "error.pickUpPoint.required": "Your pickup point",
  "error.addressCity.required": "Enter your address",
  "error.zip.required": "Enter company ZIP code",
  "src.components.molecules.PriceCard.promo": "Promo code „{promo}“",
  "src.components.routes.pages.DietHradec.title": "Hradec Králové",
  "src.components.routes.pages.DietJablonec.title": "Jablonec nad Nisou",
  "src.components.routes.pages.DietKladno.title": "Kladno",
  "src.components.routes.pages.DietSredniCechi.title": "in Central Bohemia",

  "src.components.routes.pages.DietLiberec.title": " Liberec",
  "src.components.routes.pages.DietMlda.title": "Mladá Boleslav",
  "src.components.organisms.Footer.partner.btn": "Become a partner",
  "src.components.organisms.ThankYouModal.succsesTitle":
    "Your request has been successfully received",
  "src.components.organisms.ThankYouModal.failTitle":
    "Something went wrong. Please try again",
  "src.components.organisms.GiftVoucherModal.description":
    "A gift voucher from NutritionPro is a gift everyone will appreciate",
  "src.components.atoms.OnlineProgramCard.btn.default": "Select",
  "src.components.organisms.GiftVoucherModal.titleFirst": "Make your",
  "src.components.organisms.GiftVoucherModal.titleSecond": "loved ones happy",
  "src.components.organisms.ThankYouModal.btn": "Back to homepage",

  "src.components.molecules.DeliveryInfo.title": "Delivery is always",
  "src.components.molecules.DeliveryInfo.link": "free",
  "src.components.molecules.OrderRightCard.jidel": "{jidel} course menu",
  "src.components.molecules.PromoCodeLink.promoCode": "Enter promo code",
  "src.components.molecules.OrderRightCard.btn": "Order online",
  "src.components.molecules.OrderRightCard.pocrovat": "Continue",
  "src.components.routes.pages.DietParadubice.title": "Pardubice",
  "src.components.routes.pages.DietPlzen.title": "Plzeň",
  "src.components.routes.pages.DietPraha.title": "Prague and surroundings",
  "src.components.routes.pages.Gdpr.title":
    "Basic information on personal data processing and contacts",
  "src.components.organisms.ContactForm.Checkbox.personalData.title":
    "I agree with the processing",
  "src.components.organisms.ContactForm.Checkbox.personalData.link":
    " of personal data",
  "src.components.molecules.CallMeCard.title": "Need help choosing?",
  "src.components.molecules.CallMeCard.description": "Call us.",
  "src.components.organisms.ProgramBlock.titleFirst": "Choose ",
  "src.components.organisms.ProgramBlock.titleSecond":
    " one of our diet box programs",
  "src.components.organisms.ProgramBlock.description":
    "Enjoy a wide range of flavors without limits. With all programs, you now have the option to swap any course.",
  "src.components.organisms.ContactForm.Checkbox.terms.title":
    "Consent to the processing of personal data for marketing purposes",
  "src.components.atoms.PricePerDay.dropava": "Menu price",
  "src.components.atoms.PricePerDay.dropavaDescription": "(excluding delivery)",

  "src.components.molecules.OnlineOrderFirstStepSidebar.promoCode":
    "Do you have a promo code?",
  "src.components.molecules.OnlineOrderFirstStepSidebar.order":
    "You can enter it at the end of the order.",
  "src.components.routes.pages.NotFound.title": "NotFound",
  "src.components.routes.pages.OnlineOrder.title": "OnlineOrder",
  "src.components.organisms.forms.redBtn": "I'm interested",
  "src.components.organisms.forms.GiftVoucherForm.btn": "Send",
  "src.components.organisms.forms.GiftVoucherForm.title":
    "Leave us your contact, we will get in touch with you and help you choose the right program",
  "src.components.routes.pages.Terms.title": "General terms and conditions",
  "src.components.atoms.GiftVoucher.title": "Gift voucher",
  "src.components.atoms.WorkingHours.days": "Mon - Fri",
  "src.components.molecules.Menu.count": "Open positions",
  "src.components.atoms.Copyright.text":
    "Copyright ©{currentYear} NutritionPro s.r.o",
  "src.components.organisms.ProgramBlock.meat.title": "NutritionPro Classic",
  "src.components.organisms.ProgramBlock.meat.description":
    "Our classic represents the perfect blend of delicious flavors and optimal nutrition.",
  "src.components.organisms.ProgramBlock.light.title": "NutritionPro Light",
  "src.components.organisms.ProgramBlock.vege.description":
    "Are you a vegetarian or just want to take a break from meat? Then this menu is for you. It does not contain meat, including fish.",
  "src.components.organisms.ProgramBlock.vege.title": "NutritionPro Vege",
  "src.components.organisms.ProgramBlock.light.description":
    "A lighter menu without snacks at a great price, whether snacks don't suit you or you prefer to add them yourself.",
  "src.components.organisms.ProgramBlock.fish.title": "Menu without fish",
  "src.components.organisms.ProgramBlock.fish.description":
    "Fish is not for everyone, so this menu does not include fish or any fish products.",
  "src.components.organisms.ProgramBlock.combo.title": "NutritionPro combo",
  "src.components.organisms.ProgramBlock.combo.description":
    "This is a two-course menu (lunch and dinner) that you can choose according to your preferences.",
  "src.components.molecules.SocialMedia": "Follow us on social media:",
  "src.components.organisms.Footer.location.title": "Address",
  "src.components.organisms.Footer.phone.title": "Phone",
  "src.components.organisms.Footer.email.title": "Email",
  "src.components.organisms.Footer.diet": "Top-rated box diet.",
  "src.components.organisms.Footer.links": "Links",
  "src.components.organisms.Footer.program": "Programs",
  "src.components.molecules.ProgramCard.jidel": "{jidel} meals",
  "src.components.molecules.ProgramCard.jidla": "{jidel} dishes",
  "src.components.molecules.ProgramCard.link": "More information",
  "src.components.molecules.ProgramCard.price": "from {price} CZK",
  "src.components.atoms.OnlineProgramCard.price": "from {price} CZK",
  "src.components.atoms.OnlineProgramCard.btn.acive": "Selected",
  "src.components.atoms.OnlineProgramCard.day": "day",
  "src.components.molecules.ProgramCard.day": "day",
  "src.components.molecules.ProgramCard.orderBtn": "Order",
  "src.components.molecules.ProgramCard.modalbtn": "Consult",
  "src.components.organisms.Footer.menu": "Menu",
  "src.components.organisms.Footer.price": "Price",
  "src.components.organisms.Footer.career": "Career",
  "src.components.organisms.Footer.documents": "Documents",
  "src.components.organisms.Footer.personalData": "Handling of personal data",
  "src.components.organisms.Footer.terms": "Terms and conditions",
  "src.components.organisms.Footer.cookies": "Cookie policy",
  "src.components.organisms.Footer.settings": "Open cookie settings",
  "src.components.organisms.Footer.media": "For media, partners and companies",
  "src.components.organisms.Footer.partner": "Email: {email}",
  "src.components.organisms.DesktopHeader.price": "GET A QUOTE",
  "src.components.organisms.DesktopHeader.career": "Career",
  "src.components.organisms.MobileHeader.career": "Career",
  "src.components.organisms.DesktopHeader.blog": "Blog",
  "src.components.molecules.DeliveryItem.razvoz": "Delivery",
  "src.components.molecules.DeliveryItem.praha": "Diet box Prague",
  "src.components.molecules.DeliveryItem.brno": "Diet box Brno",
  "src.components.molecules.DeliveryItem.plzen": "Diet box Plzeň",
  "src.components.molecules.DeliveryItem.mlda": "Diet box Mladá Boleslav",
  "src.components.molecules.DeliveryItem.liberec": "Diet box Liberec",
  "src.components.molecules.DeliveryItem.hradec": "Diet box Hradec Králové",
  "src.components.molecules.DeliveryItem.paradubice": "Diet box Pardubice",
  "src.components.molecules.DeliveryItem.sredniCech":
    "Diet box Central Bohemia",
  "src.components.molecules.DeliveryItem.jablonec": "Diet box Jablonec",
  "src.components.organisms.MobileHeader.program": "Diet programs",
  "src.components.organisms.MobileHeader.customerPortal": "Client zone",
  "src.components.organisms.MobileHeader.menu": "Menu",
  "src.components.organisms.MobileHeader.price": "Price",
  "src.components.organisms.MobileHeader.blog": "Blog",
  "src.components.organisms.MultiOrder.titleFirst": "Joint order",
  "src.components.organisms.MultiOrder.titleSecond":
    "with a discount of up to 20%",
  "src.components.organisms.ComplateMenuView.day": "DAY",
  "src.components.organisms.ComplateMenu.price": "{count} CZK",
  "src.components.organisms.ComplateMenu.priceTitle": "Great price",
  "src.components.organisms.ComplateMenu.titleFirst": "Try",
  "src.components.organisms.ComplateMenu.titleSecond":
    "the meal boxes for 2 days",
  "src.components.molecules.PhoneInput.label": "Your phone",
  "src.components.organisms.SpecialistBlock.arrowText":
    "{specialist} consultation with a nutrition expert is {price} for every program",
  "src.components.organisms.SpecialistBlock.arrowText.sepcialist":
    "Consultation",
  "src.components.organisms.SpecialistBlock.arrowText.price":
    "included in the price",
  "src.components.routes.pages.DietSredniCechi.subTitle":
    "The most accurate and flexible delivery",

  "src.components.organisms.SpecialistBlock.textSecond":
    "Do you have diet boxes but don't know what to eat on the weekend? Are you unsure about the best drinking regimen to pair with diet boxes? Do you want to ensure you're getting the right energy intake? Do you have other nutritional questions or are just starting? If you're unsure about anything, I'm here to help. You will be clear on everything.",
  "src.components.organisms.SpecialistBlock.sepicalist.text":
    "Expert nutritionist",
  "src.components.organisms.SpecialistBlock.sepicalist.title":
    "Jitka Dokoupilova",
  "src.components.organisms.forms.BannerForm.name": "First and Last Name",
  "src.components.organisms.forms.CallMeForm.name": "First and Last Name",
  "src.components.organisms.ComplateMenu.description":
    "Leave us your contact, and we will call you within two hours during business hours to arrange everything in 10 minutes.",
  "src.components.organisms.MultiOrder.discount":
    "First order for {count} people with a discount",
  "src.components.organisms.MultiOrder.order":
    "Order diet boxes with your partner, family, or colleagues and get a better price.",
  "src.components.organisms.HowWorks.titleFirst": "How",
  "src.components.organisms.SpecialistBlock.textFirst":
    "I am a {specialist} at NutritionPro and I live and breathe nutrition! I studied food analysis at VŠCHT and nutritional therapy at the First Faculty of Medicine. I have been advising clients for more than {years}, and during that time, I have helped over {count} clients.",
  "src.components.organisms.SpecialistBlock.specialist": "nutrition consultant",
  "src.components.organisms.SpecialistBlock.years": "15 years",
  "src.components.organisms.SpecialistBlock.count": "1500",

  "src.components.organisms.HowWorks.titleSecond": "does our diet box work?",
  "src.components.organisms.HowWorks.daily":
    "We set your daily intake according to your goals",

  "src.components.organisms.SpecialistBlock.titleFirst":
    "Always under the supervision",
  "src.components.organisms.SpecialistBlock.titleSecond":
    "of a nutrition expert",
  "src.components.organisms.HowWorks.algorithm":
    "A special algorithm creates a tailor-made menu",

  "src.components.organisms.HowWorks.cooking": "We cook the meals",
  "src.components.organisms.HowWorks.delivery":
    "We deliver it to your home or work",
  "src.components.organisms.HowWorks.goal":
    "We then ensure you reach your goal",
  "src.components.organisms.Delivery.titleFirst":
    "The most precise and flexible delivery already",
  "src.components.organisms.Delivery.titleSecond":
    " in 9 cities in the Czech Republic",
  "src.components.organisms.Delivery.titleSecondMob": " in 9 cities",
  "src.components.organisms.Delivery.titleSecondMobLast":
    "of the Czech Republic",
  "src.components.organisms.Delivery.description":
    "Check if we deliver to your area, or if one of our 16 pick-up points suits you.",
  "src.components.organisms.Delivery.praha": "Prague",
  "src.components.organisms.Delivery.brno": "Brno",
  "src.components.organisms.Delivery.plzen": "Plzeň",
  "src.components.organisms.Delivery.liberec": "Liberec",
  "src.components.organisms.Delivery.hradec": "Hradec Králové",
  "src.components.organisms.Delivery.paradubice": "Pardubice",
  "src.components.organisms.Delivery.jablonec": "Jablonec",
  "src.components.organisms.Delivery.kladno": "Central Bohemia",
  "src.components.organisms.Delivery.mlda": "Mladá Boleslav",
  "src.components.organisms.Delivery.praha.time":
    "Delivery in the evening between 17:00 - 22:00",
  "src.components.organisms.Delivery.brno.time":
    "Delivery in the evening between 18:00 - 23:00",
  "src.components.organisms.Delivery.plzen.time":
    "Delivery in the evening between 17:00 - 22:00",
  "src.components.organisms.Delivery.liberec.time":
    "Delivery in the evening between 17:00 - 22:00",
  "src.components.organisms.Delivery.hradec.time":
    "Delivery in the evening between 16:30 - 22:00.",
  "src.components.organisms.Delivery.paradubice.time":
    "Delivery in the evening between 16:30 - 22:00.",
  "src.components.organisms.Delivery.kladno.time":
    "Delivery in the evening between 17:00 - 22:00",
  "src.components.organisms.Delivery.jablonec.time":
    "Delivery in the evening between 18:00 - 22:00",
  "src.components.organisms.Delivery.mlda.time":
    "Delivery in the evening between 16:00 - 22:00",
  "src.components.organisms.Delivery.praha.replace":
    "Change time and place on the day of delivery until 10:00",
  "src.components.organisms.Delivery.brno.replace":
    "Change time and place on the day of delivery until 10:00",
  "src.components.organisms.Delivery.plzen.replace":
    "Change time and place on the day of delivery until 10:00",
  "src.components.organisms.Delivery.liberec.replace":
    "Change time and place on the day of delivery until 10:00",
  "src.components.organisms.Delivery.hradec.replace":
    "Change time and place on the day of delivery until 10:00",
  "src.components.organisms.Delivery.paradubice.replace":
    "Change time and place on the day of delivery until 10:00",
  "src.components.organisms.Delivery.kladno.replace":
    "Change time and place on the day of delivery until 10:00",
  "src.components.organisms.Delivery.jablonec.replace":
    "Change time and place on the day of delivery until 10:00",
  "src.components.organisms.Delivery.mlda.replace":
    "Change time and place on the day of delivery until 10:00",
  "src.components.organisms.Delivery.praha.point": "10 pick-up points",
  "src.components.molecules.DeliveryCard.delivery": "Free delvery",
  "src.components.organisms.HowCook.titleFirst": "Why ",
  "src.components.organisms.HowCook.titleSecond": "NutritionPro?",
  "src.components.organisms.HowCook.titleSecond.praha":
    " krabičková dieta Praha?",
  "src.components.organisms.HowCook.description":
    "Take a look at the unique process of preparing our great meals",
  "src.components.organisms.HowCook.area.title": "Modern production area",
  "src.components.organisms.HowCook.area.descritpion":
    "We use the most modern technology for the most gentle processing of ingredients.",
  "src.components.organisms.HowCook.suppliers.title": "The best suppliers",
  "src.components.organisms.HowCook.suppliers.description":
    "All our suppliers are long-term verified.",
  "src.components.organisms.HowCook.ingredients.title":
    "Only the highest quality ingredients",
  "src.components.organisms.Vege.titleFirst": "Vegetarian",
  "src.components.organisms.Vege.titleSecond": "menu",
  "src.components.organisms.Vege.list.title":
    "You can already enjoy our meat-free menu.",

  "src.components.organisms.Vege.list.description1":
    "Nutritionally balanced menu",

  "src.components.organisms.Vege.list.description2":
    "Sufficient protein intake",
  "src.components.organisms.Vege.list.description3": "Tasty and varied menu",
  "src.components.organisms.Vege.list.description4": "Premium ingredients",
  "src.components.organisms.Vege.form.title":
    "Our veggie boxes are waiting for you.",
  "src.components.organisms.Vege.form.description":
    "Enter your phone number and we'll get back to you.",
  "src.components.organisms.Vege.description":
    "Try something different and taste our meat-free dishes!",

  "src.components.organisms.HowCook.ingredients.description":
    "We check each delivery to ensure that only the highest quality ingredients are processed.",
  "src.components.organisms.HowCook.chefs.title": "Experienced chefs",
  "src.components.organisms.HowCook.chefs.descripton":
    "Our chefs cook according to the latest trends and procedures, daily preparing up to 5,000 dishes for you.",
  "src.components.molecules.GoogleMark.text":
    "{count}+ positive customer reviews",

  "src.components.routes.pages.DietPraha.deliveryTitle":
    "{greenTitle} diet box in Prague",
  "src.components.routes.pages.DietPraha.greenTitile": "Most flexible",
  "src.components.routes.pages.DietBrno.deliveryTitle":
    "{greenTitle} diet box in Brno",
  "src.components.routes.pages.DietBrno.greenTitile": "Most flexible",
  "src.components.routes.pages.DietPlzen.deliveryTitle":
    "{greenTitle} diet box in Plzeň",
  "src.components.routes.pages.DietPlzen.greenTitile": "Most flexible",
  "src.components.routes.pages.DietLiberec.deliveryTitle":
    "{greenTitle} diet box in Liberec",
  "src.components.routes.pages.DietLiberec.greenTitile": "Most flexible",
  "src.components.routes.pages.DietHradec.deliveryTitle":
    "{greenTitle} diet box in Hradec Králové",
  "src.components.routes.pages.DietHradec.greenTitile": "Most flexible",
  "src.components.routes.pages.DietHradecKralove.deliveryTitle":
    "{greenTitle} diet box in Hradec Králové",
  "src.components.routes.pages.DietHradecKralove.greenTitile": "Most flexible",
  "src.components.routes.pages.DietParadubice.deliveryTitle":
    "{greenTitle} diet box in Pardubice",
  "src.components.routes.pages.DietParadubice.greenTitile": "Most flexible",
  "src.components.routes.pages.DietSredniCechi.deliveryTitle":
    "{greenTitle} diet box in Central Bohemia",
  "src.components.routes.pages.DietSredniCechi.greenTitile": "Most flexible",
  "src.components.routes.pages.DietJablonec.deliveryTitle":
    "{greenTitle} diet box in Jablonec nad Nisou",
  "src.components.routes.pages.DietJablonec.greenTitile": "Most flexible",
  "src.components.routes.pages.DietJablonecNisou.deliveryTitle":
    "{greenTitle} diet box in Jablonec nad Nisou",
  "src.components.routes.pages.DietJablonecNisou.greenTitile": "Most flexible",
  "src.components.routes.pages.DietMldaBoleslav.deliveryTitle":
    "{greenTitle} diet box in Mladá Boleslav",
  "src.components.routes.pages.DietMldaBoleslav.greenTitile": "Most flexible",
  "src.components.routes.pages.DietMlda.deliveryTitle":
    "{greenTitle} diet box in Mladá Boleslav",
  "src.components.routes.pages.DietMlda.greenTitile": "Most flexible",
  "src.components.routes.pages.DietKladno.deliveryTitle":
    "{greenTitle} diet box in Kladno",
  "src.components.routes.pages.DietKladno.greenTitile": "Most flexible",
  "src.components.molecules.Partners.text": "Official partners",
  "src.components.organisms.RateBlock.titleFirst":
    "{greenTitle} rated diet box",
  "src.components.organisms.RateBlock.greenTitle": "Top-rated ",
  "src.components.molecules.BenefitCard.textFirst": "Choose from up to",
  "src.components.organisms.ReviewBlock.titleFirst": "Real customers ",
  "src.components.organisms.ReviewBlock.titleSecond": "say more than we do.",
  "src.components.atoms.Review.seeMore": "See all",
  "src.components.organisms.ReviewBlock.description":
    "We take an individual approach to each customer to ensure the best possible service.",
  "src.components.molecules.BenefitCard.benefit": "4 dishes ",
  "src.components.molecules.BenefitCard.textLast": "for each course.",
  "src.components.organisms.RateBlock.redBtn": "I'm interested",
  "src.components.organisms.HowCook.redBtn": "Order online",
  "src.components.organisms.CheckDelivery.title": "Check if we deliver to you.",

  "src.components.organisms.RateBlock.redBtnCity": "Select a program",

  "src.component.organisms.CheckDelivery.faild":
    "We're sorry! We are not in this location yet.",
  "src.component.organisms.CheckDelivery.success": "We deliver to your address",
  "src.component.organisms.CheckDelivery.placenholder":
    "Enter street, number, and city.",
  "src.components.molecules.AddressCheck.confirm": "Confirm",
  "src.components.organisms.Faq.titleFirst": "Frequently asked",
  "src.components.organisms.Faq.titleSecond": "questions",
  "src.components.organisms.Faq.menu.title": "Tailored menu",
  "src.components.organisms.Faq.materials.title": "Ingredients",
  "src.components.organisms.Faq.delivery.title": "Delivery and payment",
  "src.components.organisms.Faq.consumption.title": "How to consume",
  "src.components.organisms.Faq.menu.subTitle1":
    "What makes our diet box different?",
  "src.components.organisms.Faq.menu.content1":
    "Our {info} delivery is characterized by an individual approach, as we believe that every person is unique and needs a specific calorie intake to achieve their goals healthily. The menu for a 70kg woman who wants to lose weight will look entirely different from that for a 90kg man who is trying to gain muscle. Our meal delivery is designed precisely to meet your individual needs and goals, which is something that ordinary diet boxes often overlook.",
  "src.components.organisms.Faq.menu.subTitle2":
    "Who creates the menu for the diet box?",
  "src.components.organisms.Faq.menu.content2":
    "The menu for our {info} delivery is created by our special algorithm, which carefully considers your body's needs and your goals. Our nutrition expert, Jitka Dokoupilová, oversees the composition of individual meals to ensure you achieve the desired results",
  "src.components.organisms.Faq.menu.subTitle3":
    "What does the menu look like within the diet box?",

  "src.components.organisms.Faq.menu.content3":
    "Our {info} delivery is based on the principles of a balanced diet. The menu is varied and tasty, with more than 500 different dishes that are regularly updated. We constantly update our meal database to offer you new and exciting options, so our meals never get boring. Take a look at the sample menu we offer.",
  "src.components.organisms.Faq.materials.subTitle1":
    "What ingredients do you use for the diet box?",

  "src.components.organisms.Faq.materials.content1":
    "For our {info}, we use only high-quality and fresh ingredients from trusted Czech suppliers. The quality and freshness of the ingredients are the foundation of healthy eating for us.",
  "src.components.organisms.Faq.materials.subTitle2":
    "Are the meals within the diet box fresh?",
  "src.components.organisms.Faq.materials.content2":
    "Yes, all meals within our {info} are prepared from fresh ingredients that we purchase on the day of preparation. After cooking, the meals are shock-cooled and hermetically sealed, ensuring maximum freshness.",

  "src.components.organisms.Faq.materials.subTitle3":
    "When do you prepare meals for the diet box?",

  "src.components.organisms.Faq.materials.content3":
    "Meal preparation for our {info} takes place on the day of delivery. We prepare meals on Sunday for Monday and Tuesday, on Tuesday for Wednesday and Thursday, and on Thursday for Friday and Saturday.",
  "src.components.organisms.Faq.materials.subTitle4":
    "Do you also offer desserts within the diet box?",

  "src.components.organisms.Faq.materials.content4":
    "Yes, our {info} includes excellent desserts that are precisely calculated to fit into your personal menu.",
  "src.components.organisms.Faq.delivery.subTitle1":
    "How far in advance must I report skipping the delivery of the diet box?",
  "src.components.organisms.Faq.client.title": "Changes and Customer Support",
  "src.components.organisms.Faq.delivery.content1":
    "Any changes to the order within our {info}, including suspending delivery, must be reported no later than 11:00 AM three business days before the planned delivery. These rules apply because we purchase fresh ingredients for the exact number of customers for each cooking.",
  "src.components.organisms.Faq.delivery.subTitle2":
    "Can I cancel only one day within the diet box?",
  "src.components.organisms.Faq.delivery.content2":
    "If your {info} order covers two days, it is possible to cancel only the entire two-day order. Canceling just one day is not possible.",
  "src.components.organisms.Faq.delivery.subTitle3":
    "How is the price of the diet box determined?",

  "src.components.organisms.Faq.delivery.content3":
    "The price of our {info} delivery is determined individually according to your personal meal plan and daily calorie intake. The price may vary depending on your goals, whether it's losing weight, gaining muscle, or maintaining weight. You can calculate the price in our online calculator.",
  "src.components.organisms.Faq.delivery.subTitle4":
    "How do I pay for the diet box?",

  "src.components.organisms.Faq.delivery.content4":
    "After completing your {info} order, we will send you an invoice to your email. You can also choose to pay by card.",
  "src.components.organisms.Faq.delivery.subTitle5":
    "How are we different from other diet boxes?",

  "src.components.organisms.Faq.delivery.content5":
    "We believe in an individual approach. Each of us is unique, and each of us needs a different calorie intake if we want to achieve our goal healthily. The menu for a 70kg woman who just wants to improve her diet and maintain her weight will look different from that for a 90kg man who wants to gain muscle or is trying to lose weight. The menu is therefore tailored to the body and goals, which ordinary diet boxes do not consider.",
  "src.components.organisms.Faq.delivery.subTitle6":
    "Which cities do you deliver the diet box to?",

  "src.components.organisms.Faq.delivery.content6":
    "We deliver our {info} to {prague}, {chech}, {kladno}, {plzen}, {liberec}, {mlda}, {jablonec}, {paradubic}, and {hradec}.",
  "src.components.organisms.Faq.delivery.subTitle7":
    "When do you deliver meals from the diet box?",

  "src.components.organisms.Faq.delivery.content7":
    "We deliver meals from our {info} every other day – on Tuesday, Thursday, and Sunday, always between 4:00 PM and 10:00 PM.",
  "src.components.organisms.Faq.delivery.subTitle8":
    "What time will my meal be delivered?",

  "src.components.organisms.Faq.delivery.content8":
    "Prague: You can choose a specific 2-hour window for delivery (5:00 PM - 10:00 PM). Plzeň: You can choose from 3 options for time windows: 5:00 PM - 8:00 PM, 6:00 PM - 9:00 PM, 7:00 PM - 9:00 PM. In other cities, we deliver in the evening, specifically: Liberec: 5:00 PM - 10:00 PM, Mladá Boleslav: 4:00 PM - 10:00 PM, Jablonec nad Nisou: 6:00 PM - 10:00 PM, Pardubice: 4:30 PM - 10:00 PM, Hradec Králové: 4:30 PM - 10:00 PM. The exact delivery time will be specified with the driver on the day of delivery.",
  "src.components.organisms.Faq.delivery.subTitle9":
    "What if I need to change the delivery details for the diet box?",

  "src.components.organisms.Faq.delivery.content9":
    "We are flexible and understand that plans can change. You can change the time or place of delivery for our {info} on the same day until 12:00 PM.",
  "src.components.organisms.Faq.consumption.subTitle1":
    "Does the food from the diet box need to be heated?",

  "src.components.organisms.Faq.consumption.content1":
    "Some meals from our {info} are meant to be heated, while others can be eaten cold. For better taste, however, we recommend heating the meals. You can find out which meal needs to be heated by scanning the barcode on the box.",
  "src.components.organisms.Faq.consumption.subTitle2":
    "How to heat food from the diet box?",

  "src.components.organisms.Faq.consumption.content2":
    "If you're heating meals from our {info} in the microwave, we recommend poking holes in the foil. The heating time varies depending on the type of food and portion size, but we recommend using medium power (600–700 W) for even heating.",
  "src.components.organisms.Faq.consumption.subTitle3":
    "How to store meals from the diet box?",

  "src.components.organisms.Faq.consumption.content3":
    "We recommend storing meals from our {info} in the refrigerator, but you can also take them with you to work or on trips. The meals will last several hours outside the fridge without losing taste or quality.",
  "src.components.molecules.OnlineConfirmCard.price": "{price} CZK/day",
  "src.components.molecules.OnlineConfirmCard.jidel": "{jidel}-course menu",
  "src.components.atoms.ConfirmPrice.text": "Total price (incl. VAT)",
  "src.components.atoms.ConfirmPrice.price": "{price} CZK",
  "src.components.organisms.PaymentModal.successTitle":
    "Thank you, we have successfully received your payment.",

  "src.components.organisms.PaymentModal.failTitle":
    "Payment was not completed.",

  "src.components.organisms.PaymentModal.successText":
    "We are starting to process your order {number}. We have also sent this confirmation to your email.",
  "src.components.organisms.PaymentModal.failText":
    "We are sorry, but something went wrong and your payment was not completed.",
  "src.components.organisms.PaymentModal.succsesBtn": "Back to the main page",
  "src.components.organisms.PaymentModal.failBtn": "Try again",
  "src.components.organisms.PaymentModal.back": "Back to payment selection",
  "src.components.organisms.CookieModal.title":
    "To ensure NutritionPro works well for you",

  "error.phone.min": "Phone number must have at least 12 characters",
  "src.components.organisms.CookieModal.description":
    "To make your browsing experience on our site as comfortable as possible, we use cookies. Cookies help us improve our services and better offer you content that may be interesting and useful to you.",
  "src.components.organisms.CookieModal.link": "More",
  "src.components.organisms.CookieModal.accept": "Accept all",
  "src.components.organisms.CookieModal.reject": "Reject",
  "error.phone.required": "Phone number must contain 9 characters",
  "error.personal.required": "This field is required.",
  "error.name.required": "Enter your first and last name",
  "error.email.required": "Enter an email in the correct format",
  "error.faktura.required": "Invoice is required",
  "error.comment.required": "This field is required",
  "src.components.routes.pages.ThankYouAffiliate.title": "Thank you affiliate",
  "src.components.routes.pages.ThankYou.title": "Thank you",
  "src.components.organisms.MenuBlock.titleFirst": "What meals",
  "src.components.organisms.MenuBlock.titleSecond": "await you with us?",

  "src.components.organisms.MenuBlock.tab5": "Friday",
  "src.components.organisms.MenuBlock.tab4": "Thursday",
  "src.components.organisms.MenuBlock.tab3": "Wednesday",
  "src.components.organisms.MenuBlock.tab2": "Tuesday",
  "src.components.organisms.MenuBlock.tab1": "Monday",
  "src.components.organisms.MneuBlock.food1.title":
    "Coconut French toast with maple syrup and grapes",

  "src.components.organisms.MneuBlock.food2.title": "Fruit salad with cashews",

  "src.components.organisms.MneuBlock.food3.title":
    "Roast beef with honey-mustard sauce, potato wedges",
  "src.components.organisms.MneuBlock.food4.title":
    "Quiche with spinach leaves and Balkan cheese",

  "src.components.organisms.MneuBlock.food5.title":
    "Italian risotto with oyster mushrooms, white wine, and parmesan",
  "src.components.organisms.MneuBlock.food6.title":
    "Oatmeal with coconut milk, blueberries, and dark chocolate",

  "src.components.organisms.MneuBlock.food7.title":
    "Pear with nuts and chocolate",

  "src.components.organisms.MneuBlock.food8.title":
    "Chicken shashlik, baked potato slices, BBQ sauce",

  "src.components.organisms.MneuBlock.food9.title":
    "Turkey ham rolls on salad leaves with cashews and parmesan",
  "src.components.organisms.MneuBlock.food10.title":
    "Tacos with Mexican beans, grated cheddar cheese, iceberg lettuce, and tomatoes",
  "src.components.organisms.MneuBlock.food11.title":
    "Greek yogurt with forest fruits and honey",

  "src.components.organisms.MneuBlock.food12.title":
    "Old Czech poppy seed pancakes with plum sauce, cottage cheese, and grated gingerbread",
  "src.components.organisms.MneuBlock.food13.title":
    "Chicken steak Caprese, baked potato slices",

  "src.components.organisms.MneuBlock.food14.title":
    "Huevos rancheros with chickpeas",
  "src.components.organisms.MneuBlock.food15.title":
    "Salmon with broccoli, spinach, and tomatoes",
  "src.components.organisms.MneuBlock.food16.title":
    "Egg spread with cottage cheese and chives, dark bread",

  "src.components.organisms.MneuBlock.food17.title":
    "Cottage cheese dessert with blueberries",

  "src.components.organisms.MneuBlock.food18.title": "Lasagne Bolognese",
  "src.components.organisms.MneuBlock.food19.title":
    "Coconut pancakes with cream cheese and Provencal vegetables",
  "src.components.organisms.MneuBlock.food20.title":
    "Chicken medallions on thyme bulgur with baked carrot fries and grilled zucchini",

  "src.components.organisms.MneuBlock.food21.title":
    "Baked omelet with cheddar and parsley",

  "src.components.organisms.MneuBlock.food22.title": "Raw nut-date balls",
  "src.components.organisms.MneuBlock.food23.title":
    "Indian chicken Satay with peanut sauce and Basmati rice",

  "src.components.organisms.MneuBlock.food24.title":
    "Sandwich with chicken and cream cheese",

  "src.components.organisms.MneuBlock.food25.title": "Vietnamese soup Pho Bo",
  "src.components.organisms.MneuBlock.breakfast": "Breakfast",
  "src.components.organisms.MneuBlock.snack1": "Snack 1",
  "src.components.organisms.MneuBlock.snack2": "Snack 2",
  "src.components.organisms.MneuBlock.lunch": "Lunch",
  "src.components.organisms.MneuBlock.dinner": "Dinner",
  "src.component.organism.BannerBlock.title": "Order your meal boxes today.",
  "src.components.organisms.ReviewBlock.title1": "Adam Pechar",
  "src.components.organisms.ReviewBlock.description1":
    "One of the best investments I've made recently, I can only recommend it! Smooth cooperation, balanced diet, and great taste. The best diet box I've tried so far. I feel great after eating, and I always look forward to the next course. A crucial step for a healthy lifestyle. 👍 In one word, PERFECT",
  "src.components.organisms.ReviewBlock.title2": "Renata Vydrová",
  "src.components.organisms.ReviewBlock.description2":
    "The food is very good. :) And I no longer have to worry about what to cook each day. For me, it's TOP!",
  "src.components.organisms.ReviewBlock.title3": "Anton Lancevič",
  "src.components.organisms.ReviewBlock.description3": "I can only recommend!",
  "src.components.organisms.ReviewBlock.title4": "Hana Císlerová",
  "src.components.organisms.ReviewBlock.description4":
    "The best meal boxes I've ever tried. There was plenty of food, so I shared it with my son, and it ended up being cheaper than buying and cooking groceries.",
  "src.components.organisms.ReviewBlock.title5": "Margita Novotná",
  "src.components.organisms.ReviewBlock.description5":
    "Portions more than sufficient, very varied, tasty. Perfect service, arrangement, and additional recommendations with a helpful advisor without a flaw. I recommend it.",
  "src.components.organisms.ReviewBlock.title6": "Kristýna Burdová",
  "src.components.organisms.ReviewBlock.description6":
    "The meal boxes are absolutely great, they saved me during home offices when I didn't have time to cook, let alone something healthy. Salesperson Jitka helped me plan the optimal calorie intake so that I wasn't hungry and also lost a bit of weight. I was pleasantly surprised that Jitka asked about my eating and exercise habits and then tailored the meal boxes to my needs, which are very tasty. I recommend it!",
  "src.components.organisms.ReviewBlock.title7": "Jan Vítek",
  "src.components.organisms.ReviewBlock.description7":
    "The fish with the veggies was amazing! 👌",

  "src.components.organisms.ReviewBlock.title8": "Jan Kříž",
  "src.components.organisms.ReviewBlock.description8":
    "Always great taste and presentation, exactly tailored to me, excellent service around, for me a perfect service, and I'm finally fed so that I don't have to look for food elsewhere. Additionally, during the COVID situation, NutritionPro also solved the situation for people around me, who can stay and eat at home when they don't feel safe going out to shop. So thumbs up, and not just for this year, it's a clear choice. Thanks for it!!",
  "src.components.organisms.ReviewBlock.title9": "Lev Lyapeikov",
  "src.components.organisms.ReviewBlock.description9":
    "Great service! I have ordered twice for stock. Couriers arrived on time, and the food quality is excellent. My wife is at home with a 6-month-old baby, and there is no time for cooking. A 2000-calorie diet is enough for my wife all day, and I take lunch with me to work. A very profitable solution, cheaper than buying products in the store + saving time. Today I made an order for 12 days (pleasantly surprised by the low prices), for almost two weeks I'm free from shopping. Thank you so much for the yummy food and services!",
  "src.components.organisms.ReviewBlock.title10": "Nicole Ponce",
  "src.components.organisms.ReviewBlock.description10":
    "For someone who is a very picky eater, I would have to say that the meals from NutritionPro have exceeded my expectations and more. It makes my day so much easier to know that I have my meals prepared and ready every day, straight to my door. I would highly recommend it.",
  "src.components.organisms.ReviewBlock.title11": "Aneta Benova",
  "src.components.organisms.ReviewBlock.description11":
    "Very tasty and varied food :)",

  "src.components.organisms.ReviewBlock.title12": "Antoine Bourg",
  "src.components.organisms.ReviewBlock.description12":
    "Super satisfied, the recipes are simple yet diverse, and delightful. Feels like home cooking, and the results are seen very early on. Would recommend it to anyone looking for a tailored nutrition plan!!",
  "src.components.organisms.ReviewBlock.title13": "Olina Moroz",
  "src.components.organisms.ReviewBlock.description13":
    "(Original) Мне очень понравилось. Меню разнообразное и, самое главное, вкусное. Советую! (Translation) I really liked it. The menu is varied and, most importantly, delicious. I recommend it!",
  "src.components.organisms.ReviewBlock.title14": "Ondřej Staněk",
  "src.components.organisms.ReviewBlock.description14":
    "Excellent meal boxes. They don't repeat much, and you often get to try exotic foods you wouldn't normally come across. Portions are great and balanced. I'm definitely not hungry, and I'm naturally losing weight slowly.",
  "src.components.organisms.ReviewBlock.title15": "Tereza Žatečková",
  "src.components.organisms.ReviewBlock.description15":
    "I've been getting the meal boxes for a while now, and they still maintain their quality. The meals are varied, fresh, and the delivery is always on time. The quality/price ratio is appropriate. I recommend it👍",
  "src.components.organisms.ReviewBlock.title16": "Viktor Nyitray",
  "src.components.organisms.ReviewBlock.description16":
    "I am very satisfied with the meal boxes. They are not the classic meals from a canteen packed into boxes, but the food is really tasty! I recommend at least trying it :)",
  "src.components.organisms.ReviewBlock.title17": "Irena Rabochova",
  "src.components.organisms.ReviewBlock.description17":
    "The food is very tasty and balanced, made from fresh ingredients. Time-saving and weight loss. The service is top-notch, especially the delivery.",
  "src.components.organisms.ReviewBlock.title18": "Lucie Bartošová",
  "src.components.organisms.ReviewBlock.description18":
    "The food is absolutely great, but there's one thing even better – the delivery staff, especially one lady who always brightens my day, she's just perfect.",
  "src.components.organisms.ReviewBlock.title19": "Tereza Hofmanová",
  "src.components.organisms.ReviewBlock.description19":
    "Very good meal boxes, I like the variety of the meals. A clear choice for me during the busy periods when I don't have time to cook. I RECOMMEND!",
  "src.components.organisms.ReviewBlock.title20": "Zdenka Svobodova",
  "src.components.organisms.ReviewBlock.description20":
    "Very tasty food, very diverse in terms of ingredients. And you get to try some unusual food as well. Some combinations seemed strange to me (like lentils with fish), but they were always really well prepared, and despite the initial strangeness, the flavors worked well together. You can choose between sweet or savory snacks. I have a sweet tooth ;) and these snacks fully satisfy my cravings while still helping me lose some weight. At first, I struggled with the small portions, but that was due to my previous overeating. Within three days, I got used to it, and after a month, I can say that the portions are just right, diverse, and most importantly, they taste good. I don't even feel like I'm on a diet ;) and I look forward to the meals. The delivery is also great, three times a week, even in the late hours, and the lady who delivers to me is very kind. Thank you and keep up the good work ;)",
  "src.components.organisms.ReviewBlock.title21": "Linda Dlabačová",
  "src.components.organisms.ReviewBlock.description21": "New menu",
  "src.components.organisms.ReviewBlock.title22": "Maldonado David",
  "src.components.organisms.ReviewBlock.description22": "Super tasty food",
  "src.components.organisms.ReviewBlock.title23": "Matěj Schultz",
  "src.components.organisms.ReviewBlock.description23":
    "The best price/quality ratio, you've helped me immensely, thank you so much!!",

  "src.components.organisms.ReviewBlock.title24": "Klára Onderková",
  "src.components.organisms.ReviewBlock.description24":
    "Always timely delivery and great service, thanks for saving my time Nutrition Pro!!",
  "src.components.organisms.ReviewBlock.title25": "Aneta Pecková",
  "src.components.organisms.ReviewBlock.description25":
    "Possibly the first service of this kind that didn't disappoint me in the first week, on the contrary, it's fantastic!!",
  "src.components.organisms.ReviewBlock.title26": "Gregory Pelec",
  "src.components.organisms.ReviewBlock.title27":
    "It's been a while since I started my plan with you, and surprisingly, every meal was very delicious, and the kilos finally went down!! Highly recommend the Home Office pack to those who spend every day in the office like I do.",
  "src.components.organisms.ReviewBlock.title28": "Jitka Beranová",
  "src.components.organisms.ReviewBlock.description28":
    "Coming to the end of my first month with you, and I already feel a thousand times better, minus 3 kilos, and finally have time and energy. You are absolutely awesome!",
  "src.components.organisms.ReviewBlock.title29": "Jan Kříž",
  "src.components.organisms.ReviewBlock.description29":
    "Always great taste and presentation, just right for me, excellent service around it, for me, the perfect service, and finally, I'm fed well enough that I don't have to look for additional food elsewhere. Moreover, during the COVID situation, NutritionPro solved the issue for people around me who can stay and eat at home when they don't feel safe going out to shop. So thumbs up, and not just for this year, it's the clear choice. Thanks for it!!",
  "src.components.organisms.ReviewBlock.title30": "Eldar Alizade",
  "src.components.organisms.ReviewBlock.description30":
    "The food tastes great, I'm not hungry, and most importantly, I've lost a few kilos in a month! That was the main goal. Moreover, the consultants are helpful, and the communication is excellent. So everything is great, I recommend it!",
  "src.components.organisms.ReviewBlock.title31": "Káčko Béčko",
  "src.components.organisms.ReviewBlock.description31":
    "A pleasant surprise, very satisfied (I can compare it with competitors). The food is to my taste, I've ordered again.",
  "src.components.organisms.ReviewBlock.title32": "Patrick Beznoska",
  "src.components.organisms.ReviewBlock.description32":
    "Great service and food too",

  "src.components.organisms.ReviewBlock.title33": "Alan Pock",
  "src.components.organisms.ReviewBlock.description33":
    "This is awesome, after a month I really like it, mostly because I don't have to worry and constantly think about what to eat.",
  "src.components.organisms.ReviewBlock.title34": "Pavel Jiranek",
  "src.components.organisms.ReviewBlock.description34":
    "Tasty, quick, and neat solution for your daily eating habits!",

  "src.components.organisms.ReviewBlock.title35": "Petra Dolanská",
  "src.components.organisms.ReviewBlock.description35":
    "Luxurious food, great communication, and just the right portions. I can only RECOMMEND.",

  "src.components.organisms.ProgramBlock.meat.description1": "fully balanced",
  "src.components.organisms.ProgramBlock.meat.description2":
    "with a rich palette of ingredients",

  "src.components.organisms.ProgramBlock.meat.description3":
    "emphasizes variety",

  "src.components.organisms.ProgramBlock.meat.description4":
    "minerals and vitamins",

  "src.components.organisms.ProgramBlock.meat.description5": "fiber.",
  "src.components.organisms.ProgramBlock.meat.description6": " 3-5 courses",
  "src.components.organisms.ProgramBlock.meat.description7":
    "option to swap dishes",

  "src.components.organisms.ProgramBlock.meat.description8":
    "try some of our vegetarian dishes",

  "src.components.organisms.ProgramBlock.meat.description9":
    "seasonal ingredients,",

  "src.components.organisms.ProgramBlock.meat.description10": "top reviews",
  "src.components.organisms.ProgramBlock.meat.description11":
    "findings and research in line with nutritional standards",

  "src.components.organisms.ProgramBlock.meat.description12":
    "nutrition specialists.",

  "src.components.organisms.ProgramBlock.meat.description13":
    "caloric options,",

  "src.components.organisms.ProgramBlock.meat.description14":
    "current body weight, reduce body weight",

  "src.components.organisms.ProgramBlock.meat.description15":
    " gain muscle mass.",

  "src.components.organisms.ProgramBlock.vege.description1":
    "don't want meat and fish.",
  "src.components.organisms.ProgramBlock.vege.description2":
    "a diverse range of ingredients",
  "src.components.organisms.ProgramBlock.vege.description3":
    "sufficient protein, fiber, minerals, and vitamins.",

  "src.components.organisms.ProgramBlock.vege.description4": "3-5 courses",
  "src.components.organisms.ProgramBlock.active.description1":
    "active athletes",

  "src.components.organisms.ProgramBlock.active.description2":
    "higher energy and nutrient intake.",

  "src.components.organisms.ProgramBlock.active.description3":
    "rich in protein",
  "src.components.organisms.ProgramBlock.active.description4":
    "lower fat content.",

  "src.components.organisms.ProgramBlock.active.description5":
    "all nutrients in optimal proportions",

  "src.components.organisms.ProgramBlock.fish.description1":
    "without fish and fish products.",

  "src.components.organisms.ProgramBlock.fish.description2":
    "a wide selection of tasty dishes,",

  "src.components.organisms.ProgramBlock.fish.description3":
    "nutritionally valuable food.",

  "src.components.organisms.ProgramBlock.fish.description4": "3-5 courses",
  "src.components.organisms.ProgramBlock.office.description1":
    "two-course menu",

  "src.components.organisms.ProgramBlock.office.description2":
    "choose according to your preferences.",

  "src.components.organisms.WhyNutrition.item1.title":
    "Option to swap any course",

  "src.components.organisms.WhyNutrition.item2.title":
    "Our meals average a 4.6/5 rating",

  "src.components.organisms.WhyNutrition.item3.title": "New menu every day",
  "src.components.organisms.WhyNutrition.item4.title":
    "A personalized meal plan to meet your goals",
  "src.components.organisms.WhyNutrition.item5.title":
    "The most accurate delivery time",

  "src.components.organisms.WhyNutrition.item6.title":
    "The most flexible delivery",
  "src.components.organisms.WhyNutrition.item7.title":
    "Nutritional consultation included",

  "src.components.organisms.WhyNutrition.item8.title":
    "The most professional meal preparation",

  "src.components.organisms.WhyNutrition.item1.description":
    "Each of us is unique, and not everyone likes the same things. That's not a problem with us. You can swap any course for another dish at no extra cost.",
  "src.components.organisms.WhyNutrition.item2.description":
    "We actively collect feedback from clients on every meal and continuously improve our menu based on it.",
  "src.components.organisms.WhyNutrition.item3.description":
    "Our menu consists of more than 300 dishes. Over 4 weeks, you won't see any dish twice. You can look forward to your favorites again next month.",
  "src.components.organisms.WhyNutrition.item4.description":
    "Thanks to our special algorithm, we can tailor a meal plan to each customer's needs.",
  "src.components.organisms.WhyNutrition.item5.description":
    "We deliver within two-hour time slots that you can change at your convenience. We bring the food right to your door at the time you choose.",
  "src.components.organisms.WhyNutrition.item6.description":
    "Don't wait at home all evening for the courier. You can flexibly adjust the delivery time and place until almost the last minute. Just call or text us.",
  "src.components.organisms.WhyNutrition.item7.description":
    "From day one, you're on the right path. Our nutritional consultants and nutrition expert will help you with your dietary goals for free.",
  "src.components.organisms.WhyNutrition.item8.description":
    "Your meals are prepared under the supervision of a chef, a nutrition specialist, and a production technologist, ensuring the highest quality of the prepared dishes.",
  "src.components.organisms.WhyNutrition.redBtn": "Calculate your price",
  "src.components.organisms.WhyNutrition.titleFirst": "Why NutritionPro",
  "src.components.organisms.WhyNutrition.titleSecond": "box diet?",
  "src.components.organisms.CityDeliveryBlock.list.description1":
    "We deliver in the green area.",

  "src.components.organisms.CityDeliveryBlock.list.description2":
    "Evening delivery between {clock}",

  "src.components.organisms.CityDeliveryBlock.list.descriptionPlzen":
    "You can choose from three delivery time slots, no more chasing couriers all evening!",
  "src.components.organisms.CityDeliveryBlock.list.description3":
    "Have your plans changed? You can flexibly change the time and place on the day of delivery until 10:00 AM.",
  "src.components.organisms.CityDeliveryBlock.list.title": "How does it work?",
  "src.components.organisms.CityDeliveryBlock.title":
    "The most accurate and flexible delivery in",

  "src.components.organisms.CallMeBlock.call": "Call me",
  "src.components.organisms.CallMeBlock.order": "Order online",
  "src.components.organisms.CallMeModal.title": "Call me",
  "src.components.organisms.CallMeModal.programTitle":
    "Non-binding consultation",

  "src.components.organisms.CallMeModal.programDescription":
    "Need help choosing, or is something unclear? Leave us your contact, and we will get back to you.",
  "src.components.organisms.DesktopHeader.program": "Programs",
  "src.components.organisms.DesktopHeader.customerPortal": "Customer portal",
  "src.components.organisms.DesktopHeader.menu": "Menu",
  "src.components.organisms.NotFoundBlock.title": "Page not found",
  "src.components.organisms.NotFoundBlock.btn": "Back to home page",
  "src.components.organisms.Delivery.brno.point": "1 pick-up point",
  "src.components.organisms.Delivery.plzen.point": "1 pick-up point",
  "src.components.organisms.Delivery.liberec.point": "1 pick-up point",
  "src.components.organisms.Delivery.hradec.point": "1 pick-up point",
  "src.components.organisms.Delivery.mlda.point": "1 pick-up point",
  "src.components.organisms.Delivery.kladno.point": "1 pick-up point",
  "src.components.organisms.Delivery.kladno.info":
    "One-hour window notification by SMS",

  "src.components.routes.pages.Terms.description":
    "of Nutritionpro s.r.o. located at Inovační 122, 252 41 Zlatníky-Hodkovice, IČO 07752971",
  "src.components.routes.pages.Terms.content1.title": "I. Definitions",
  "src.components.routes.pages.Terms.content2.title": "II. Order",
  "src.components.routes.pages.Terms.content3.title":
    "III. Order Change/Cancellation",

  "src.components.routes.pages.Terms.content4.title": "IV. Delivery",
  "src.components.routes.pages.Terms.content5.title":
    "V. NutritionPro Program Defects and Complaints",
  "src.components.routes.pages.Terms.content6.title":
    "VI. NutritionPro Program Pricing",

  "src.components.routes.pages.Terms.content7.title": "VII. Final Provisions",
  "src.components.routes.pages.Terms.content1.description1":
    "For the purposes of these General Terms and Conditions, the terms listed below have the following meanings:",
  "src.components.routes.pages.Terms.content1.description2":
    "Nutritionpro s.r.o., located at Inovační 122, 252 41 Zlatníky-Hodkovice, IČO 07752971,",
  "src.components.routes.pages.Terms.content1.description3":
    "email: info@nutritionpro.cz, phone: +420 226 288 200 (hereinafter referred to as the 'Operator')",
  "src.components.routes.pages.Terms.content1.description4":
    "The NutritionPro Program is a regular healthy eating program that includes the preparation and delivery of daily meal sets according to the number of daily/weekly/monthly cycles selected and ordered by the client, delivered to the location specified by the client or to a pick-up point. (hereinafter referred to as the 'NutritionPro Program')",
  "src.components.routes.pages.Terms.content1.description5":
    "A daily meal set is a balanced combination of food for one day, delivered to the client by the Operator. The daily meal set will be individually tailored to provide optimal macronutrient intake based on the information from the initial questionnaire completed by the client and the initial body composition data. The food in each daily set is neatly organized, labeled, packaged, and delivered to the client every other day at the location specified by the client or to a pick-up point.",
  "src.components.routes.pages.Terms.content1.description6":
    "The client is the person ordering the NutritionPro Program on the website www.nutritionpro.cz, as a person interested in the services offered in the form of regular meals consisting of nutritionally balanced and valuable foods. The client agrees to pay the Operator the agreed fee for these services. (hereinafter referred to as the 'Client')",
  "src.components.routes.pages.Terms.content2.description1":
    "1. An order for the NutritionPro Program can be made in the following ways:",

  "src.components.routes.pages.Terms.content2.description2":
    "2. by phone at +420 226 288 200 when the purchase contract is concluded by confirming the order by the Operator during the call,",
  "src.components.routes.pages.Terms.content2.description3":
    "3. by email to the addresses listed on the website www.nutritionpro.cz, when the purchase contract is concluded by confirming the order by the Operator by email, by phone, or by delivering the NutritionPro Program (in the case of repeat delivery of the NutritionPro Program, when the Client responds with a new order to the Operator's email announcing the upcoming end of the previous order),",
  "src.components.routes.pages.Terms.content2.description4":
    "4. through the online form located on the website www.nutritionpro.cz, when the purchase contract is concluded by phone confirmation of the order by the Operator.",
  "src.components.routes.pages.Terms.content2.description5":
    "5. The NutritionPro Program order must be made at least 3 business days before the day the NutritionPro Program is to be delivered. Each order made is a binding proposal for the conclusion of a purchase contract.",
  "src.components.routes.pages.Terms.content2.description6":
    "6. If the client suffers from an allergy to any food, they must immediately inform the Operator by phone, and a procedure for handling such a situation will be agreed upon.",
  "src.components.routes.pages.Terms.content3.description1":
    "1. The client is obliged to inform the Operator of any changes to the order, such as the cancellation of the NutritionPro Program order or changes to the ordered program, at least 3 business days before the day the NutritionPro Program is to be delivered. Changes that the client informs the Operator about within a shorter period than specified in the previous sentence will not be accommodated by the Operator, the NutritionPro Program will be duly delivered to the client, and the client is obliged to pay the Operator the full price for the delivered NutritionPro Program.",

  "src.components.routes.pages.Terms.content3.description2":
    "2. If the Client changes/cancels the order during customer support working hours, i.e., Mon-Fri from 10:00 AM - 12:00 PM and from 1:00 PM - 6:00 PM at phone number +420 226 288 200, no later than 3 full business days before the delivery date (the delivery date itself is not counted, i.e., delivery date minus 3 full business days), the Operator will make the change/cancellation of the modified/canceled order.",
  "src.components.routes.pages.Terms.content4.description1":
    "1. The Operator undertakes to produce the NutritionPro Program according to the Client's order and deliver it to the location specified by the Client and hand it over to the Client in the manner specified in paragraph 2 of this article.",
  "src.components.routes.pages.Terms.content4.description2":
    "2. The driver delivering the NutritionPro Program will hand over the daily meal set to the Client, the person designated by the Client, or another person present at the location designated by the Client as the delivery location for the NutritionPro Program. The risk of damage to the NutritionPro Program passes to the Client upon delivery to the Client. If there is no one present at the location designated by the Client as the delivery location, and the client is not reachable by phone, the NutritionPro Program will be stored in a warehouse, and the Client will be informed of the pick-up options.",
  "src.components.routes.pages.Terms.content4.description3":
    "3. The daily meal set is delivered in a chilled state and must be kept chilled until consumption, at a temperature of up to 5°C. The Operator is not responsible for the quality of the NutritionPro Program if the storage conditions are violated after its delivery to the Client.",
  "src.components.routes.pages.Terms.content4.description4":
    "4. The daily meal set must be consumed no later than the date indicated on the label. Once opened, it must be consumed immediately.",
  "src.components.routes.pages.Terms.content4.description5":
    "5. The daily meal set will be delivered to the designated location between 5:00 PM and 10:00 PM. The client is aware that in exceptional cases, the delivery time may change due to traffic conditions and similar events. The client will be informed of this change by phone.",
  "src.components.routes.pages.Terms.content4.description6":
    "6. If the Client needs to change the agreed delivery time to the location specified by the client, the client is entitled to make this change by email or by phone at +420 226 288 200 by 12:00 PM on the day of delivery of the daily meal set.",
  "src.components.routes.pages.Terms.content5.description1":
    "1. The Operator is not responsible for defects caused by unauthorized intervention, improper storage, improper handling, use of the goods for purposes other than those intended, natural disasters, or force majeure after the goods have been handed over to the Client.",
  "src.components.routes.pages.Terms.content5.description2":
    "Upon receipt of the goods, the buyer is obliged to check whether the delivered goods show any defects caused during transport. If so, the buyer is obliged not to accept the goods and to inform the seller immediately by email at: info@nutritionpro.cz using the form {button} The buyer is also obliged to deliver the goods without defects",
  "src.components.routes.pages.Terms.content5.description3":
    "3. In the case of a quality complaint, the seller's driver will pick up the complained goods. The condition for a quality complaint is that no more than 10% of the food portion is missing. If the complaint is assessed as justified by the seller, it will be resolved either by replacement with another meal of the same price range or by refunding the purchase price in the next order.",
  "src.components.routes.pages.Terms.content5.description4":
    "4. The client is obliged to substantiate the legitimacy of their claim, which is made through the form according to paragraph 2 above, with an attached photograph documenting the claimed defect.",
  "src.components.routes.pages.Terms.content6.description1":
    "1. The client undertakes to accept the ordered NutritionPro Program at the location specified by them from the Operator and to pay the Operator the Purchase Price for this Program properly and on time.",
  "src.components.routes.pages.Terms.content6.description2":
    "2. The Operator will invoice the Purchase Price to the Client by an invoice with a due date within 7 days from the date of issue of the invoice. The Operator is entitled to issue an invoice to the Client on the day the Client orders the NutritionPro Program and send the invoice to the Client by email.",
  "src.components.routes.pages.Terms.content6.description3":
    "3. The client will pay the Purchase Price to the Operator based on the issued invoice by bank transfer to the Operator's bank account under the variable symbol specified in the invoice.",
  "src.components.routes.pages.Terms.content6.description4":
    "4. The client will pay the Purchase Price to the Operator based on the issued invoice by bank transfer to the Operator's bank account under the variable symbol specified in the invoice.",
  "src.components.routes.pages.Terms.content6.description5":
    "5. The Purchase Price is considered paid at the moment of its crediting to the Operator's bank account.",
  "src.components.routes.pages.Terms.content6.description6":
    "6. In case of delay in payment of the Purchase Price, the Operator may demand interest on arrears from the Client.",
  "src.components.routes.pages.Terms.content6.description7":
    "7. In case of delay in payment of the Purchase Price, the Operator may refuse to deliver the ordered Program until the full payment of the Purchase Price for the previous deliveries of the NutritionPro Program.",
  "src.components.routes.pages.Terms.content7.description1":
    "1. All conditions not explicitly regulated by the contract are governed by generally binding legal regulations of the Czech Republic, especially the provisions of Act No. 89/2012 Coll., the Civil Code, as amended.",
  "src.components.routes.pages.Terms.content7.description2":
    "2. These binding contractual terms are valid and effective from the date of their publication on the Operator's website. The Operator will inform clients in advance of any changes or additions to these binding contractual terms. If any provision of these contractual terms subsequently proves to be invalid or ineffective, this does not mean the invalidity of the contractual terms as a whole. The Operator undertakes to replace such invalid or ineffective provision with another provision and to inform the client of this change. If the client does not express disagreement with the change within 30 days from the date of its notification, it is considered that they agree with the change in the contractual terms.",
  "src.components.routes.pages.Terms.content7.description3":
    "3. The acceptance of these contractual terms by the client is considered bindingly agreed upon between the client and the operator, and the client has no reservations about the content of the Contract and the contractual terms.",
  "src.components.routes.pages.Terms.btn": "LINK HERE",
  "src.components.organisms.forms.TermsForm.btn": "Submit",
  "src.components.organisms.TermsModal.title": "Complaint form",
  "src.components.organisms.forms.TermsForm.checkbox":
    "Consent to the processing of personal data for marketing purposes.",

  "src.components.routes.pages.Gdpr.description1":
    "Nutritionpro s.r.o., located at Inovační 122, 252 41 Zlatníky-Hodkovice, IČO 07752971, email: info@nutritionpro.cz, phone: +420 777 510 839 (hereinafter referred to as the 'Operator') in accordance with Regulation (EU) 2016/679 of the European Parliament and of the Council on the protection of natural persons with regard to the processing of personal data and on the free movement of such data (hereinafter referred to as: 'GDPR') respects the principles of personal data protection and processes only those personal data that serve to provide the service selected by the customer and for the period necessary for this purpose.",
  "src.components.routes.pages.Gdpr.description2":
    "The Operator also processes personal data of representatives of customers (contracting parties) and also potential customers who are interested in the services of the Operator. The purposes of processing personal data as well as information on legal titles, legitimate interests, and information regarding the fact that personal information is processed only based on the client's free and active consent are always contained in the concluded contract or in the terms and conditions published on the Operator's website. Personal data is also processed to fulfill the legal obligations of the Operator arising from the Tax Code or other legal regulations, as well as for other purposes that pursue the legitimate interest of the Operator, namely:",
  "src.components.routes.pages.Gdpr.description3":
    "1. recording clients who have been contacted by the Operator's sales representatives in connection with the offer to conclude a contract, or",
  "src.components.routes.pages.Gdpr.description4":
    "2. for the purpose of fraud prevention, purposes {info}",
  "src.components.routes.pages.Gdpr.description4.info":
    "of checking established technological processes",

  "src.components.routes.pages.Gdpr.description5":
    "3. recruitment of new employees who provide the Operator with their personal data during the interview process. The data of unsuccessful applicants is retained for a maximum of 6 months for the purpose of possibly using it to contact them with another job offer/participation in another or repeated selection process for a job position unless otherwise agreed by the parties,",
  "src.components.routes.pages.Gdpr.description6":
    "4. direct marketing, which means the processing of personal data of existing customers for the purpose of direct contact with a business offer or research. The Operator points out that if the customer objects to direct marketing for a particular service, the Operator will no longer process the customer's personal data for this purpose, nor will it contact the customer/send business offers,",
  "src.components.routes.pages.Gdpr.description7":
    "5. enforcement and defense of the Operator's claims, or",

  "src.components.routes.pages.Gdpr.description8":
    "for the purpose of enforcement and assertion of legal claims in complaints, legal disputes, and enforcement proceedings.",
  "src.components.routes.pages.Gdpr.description9":
    "The information contained in this document supplements the information provided to customers in the concluded contract.",
  "src.components.routes.pages.Gdpr.description10":
    "Identity and contact details of the controller",

  "src.components.routes.pages.Gdpr.description11":
    "Nutritionpro s.r.o., located at Inovační 122, 252 41 Zlatníky-Hodkovice, IČO 07752971, email: info@nutritionpro.cz, phone: +420 777 510 839",
  "src.components.routes.pages.Gdpr.description12":
    "Contact details of the Data Protection Officer",

  "src.components.routes.pages.Gdpr.description13":
    "In all matters related to the processing of your personal data, our Data Protection Officer is available to you.",
  "src.components.routes.pages.Gdpr.description14":
    "The Data Protection Officer can be contacted by email at info@nutritionpro.cz",

  "src.components.routes.pages.Gdpr.description15":
    "Notice of the right to object",

  "src.components.routes.pages.Gdpr.description16":
    "In all cases of processing personal information by the Operator, the client has the right to object to such processing. The objection will then be assessed, and if there are appropriate legal reasons, it will be upheld.",
  "src.components.routes.pages.Gdpr.description17": "Security",
  "src.components.routes.pages.Gdpr.description18":
    "All processed personal data is secured with appropriate organizational and technical measures.",
  "src.components.routes.pages.Gdpr.description19":
    "Recipients of Personal Data",
  "src.components.routes.pages.Gdpr.description20":
    "A recipient of personal data is a natural or legal person, public authority, agency, or other entity to which the personal data is disclosed, whether a third party or not. Public authorities that may receive personal data in the context of a specific inquiry in accordance with the law of a Member State are not regarded as recipients; the processing of such personal data by these public authorities must comply with the applicable data protection rules for the specific purposes of the processing.",
  "src.components.routes.pages.Gdpr.description21":
    "The Operator transfers personal data only to the following recipients who participate in the delivery of goods/services/payment processing based on the contract or provide marketing services.",
  "src.components.routes.pages.Gdpr.description22":
    "The controller does not intend to transfer personal data to a third country (outside the EU) or an international organization.",
  "src.components.routes.pages.Gdpr.description23":
    "The basic period during which personal data is stored is always specified in the contract concluded between the client and the Operator.",
  "src.components.routes.pages.Gdpr.description24":
    "The basic period during which personal data is stored is always specified in the contract concluded between the client and the Operator.",
  "src.components.routes.pages.Gdpr.description25":
    "The personal data of potential clients who have provided their personal data but have not concluded a contract is stored for 1 year for record-keeping purposes.",
  "src.components.routes.pages.Gdpr.description26":
    "For record-keeping purposes, the personal data of clients is stored for 10 years from the termination of the contract.",
  "src.components.routes.pages.Gdpr.description27": "Stored Data",
  "src.components.routes.pages.Gdpr.description28.info": "Contact Details:",
  "src.components.routes.pages.Gdpr.description28":
    "{info} Name, address, phone number, email address, company identification number (IČO). Processing is necessary for the performance of a contract to which the data subject is a party or to take steps at the request of the data subject prior to entering into a contract, or processing is necessary for the purposes of legitimate interests pursued by the controller or a third party, except where such interests are overridden by the interests or fundamental rights and freedoms of the data subject that require protection of personal data. The purpose is for the Operator to know what the Client requires and why they contacted the Operator.",
  "src.components.routes.pages.Gdpr.description29.info1": "Location Data:",
  "src.components.routes.pages.Gdpr.description29.info2":
    "The reasons and purposes are similar to those mentioned above for contact details, particularly for delivery purposes.",
  "src.components.routes.pages.Gdpr.description29":
    "{info1} Address, postal code, city, state. {info2}",
  "src.components.routes.pages.Gdpr.description30.info1": "Communication Data:",
  "src.components.routes.pages.Gdpr.description30.info2":
    "The reasons and purposes are similar to those mentioned above for contact details, particularly so that the Operator can contact the Client for the purpose of fulfilling the contract, with appropriate and respectful communication, or for resolving issues related to delivery or handling complaints.",
  "src.components.routes.pages.Gdpr.description30":
    "{info1} Name, email address, phone number. {info2}",

  "src.components.routes.pages.Gdpr.description31.info1": "Payment Data:",
  "src.components.routes.pages.Gdpr.description31.info2":
    "The reasons and purposes are similar to those mentioned above for contact details, particularly so that the Operator can track payments and associate them with the relevant orders or for handling complaints.",
  "src.components.routes.pages.Gdpr.description31":
    "{info1} Payment method. {info2}",
  "src.components.routes.pages.Gdpr.description32.info1": "Delivery Data:",

  "src.components.routes.pages.Gdpr.description32.info2":
    "The reasons and purposes are similar to those mentioned above for contact details, particularly for delivering orders. Only the necessary data for delivery is provided to our drivers.",
  "src.components.routes.pages.Gdpr.description32":
    "{info1} Name, delivery address, phone number, customer identification number. {info2}",
  "src.components.routes.pages.Gdpr.description33": "Other Stored Data",
  "src.components.routes.pages.Gdpr.description34":
    "All other information, personal data provided by clients about other individuals with whom they cooperate or whose data is necessary for fulfilling the contractual relationship, is also stored. The client is obliged to inform these individuals similarly about the transfer of their personal data.",
  "src.components.routes.pages.Gdpr.description35":
    "The Operator also collects personal data obtained during phone calls or on social networks (e.g., Facebook, Twitter, etc.). Phone calls may be monitored and recorded to ensure the quality and proper functioning of our services. Recordings may be stored for up to six months for internal purposes only. The reasons and purposes are similar to those mentioned above for contact details.",
  "src.components.routes.pages.Gdpr.description36":
    "Specific Purposes of Data Processing",
  "src.components.routes.pages.Gdpr.description37.info": "Drivers/Deliverers:",
  "src.components.routes.pages.Gdpr.description37":
    "{info} These individuals, whether employed or in a similar relationship, or third parties entrusted with delivering the order, are provided with the necessary data for order delivery. The category of personal data is delivery data in accordance with Article 6(1)(b) GDPR.",
  "src.components.routes.pages.Gdpr.description38.info1": "Direct Marketing:",
  "src.components.routes.pages.Gdpr.description38.info2":
    "The category of personal data is",

  "src.components.routes.pages.Gdpr.description38":
    "{info1} By providing your email address, you agree to receive our commercial offers via email. The processing of personal data (phone numbers, email addresses, etc.) for the purposes of direct marketing (for sending commercial communications) is processing carried out for the legitimate interest according to Article 6(1)(f) GDPR. We hereby inform our clients of their right to object under Article 21(2) GDPR to the processing of personal data for direct marketing purposes. If the Client raises this objection, personal data may no longer be processed for this purpose (this is the so-called opt-out principle). See below, revoking your consent. {info2} contact details and location data. If the client does not agree from the beginning with receiving commercial communications, emails, etc., as part of direct marketing, they should notify the Operator of this request during their first order or send this communication in advance to the email address provided below. See revoking your consent.",
  "src.components.routes.pages.Gdpr.description39.info": "Targeting.",
  "src.components.routes.pages.Gdpr.description39":
    "{info} Targeting involves customizing, i.e., disabling, activating, and modulating advertising banners on websites for certain target groups to most effectively communicate to the website user/potential customer on an individualized basis.",
  "src.components.routes.pages.Gdpr.description40":
    "Retargeting. Retargeting refers to the use of cookies when revisiting the Operator's website. This feature can also be disabled by deactivating add-ons in your web browser or limited by deleting cookies. The category of personal data is contact details.",
  "src.components.routes.pages.Gdpr.description41":
    "Cookies. A cookie is a short text file that a visited website saves in your browser. It allows the website to record information about your visit, making your next visit easier and the website more useful. Most browsers allow you to control how cookies are set and used when browsing and to delete cookies and browsing data. Your browser may also offer management of cookies on individual websites. You can express your disagreement with our use of cookies by sending a relevant message to the email address provided below. We will address your request without undue delay, but the Operator points out that this disagreement will only take effect within the web browser from which you create your disagreement.",
  "src.components.routes.pages.Gdpr.description42.info":
    "Revoking Your Consent",

  "src.components.routes.pages.Gdpr.description42":
    "{info} can be done by stating that you revoke your consent to the use of personal data to info@nutritionpro.cz. Send any objections to the processing of personal data to this address as well.",
  "src.components.routes.pages.Gdpr.description43": "Your Rights",
  "src.components.routes.pages.Gdpr.description44":
    "1. Under the conditions set out in the GDPR, you also have the right to lodge a complaint with the Data Protection Authority if you believe your right to personal data protection has been violated.",
  "src.components.routes.pages.Gdpr.description45":
    "2. You also have the right to lodge a complaint with the Data Protection Authority if you believe your right to personal data protection has been violated.",
  "src.components.routes.pages.Gdpr.description46": "Final Provisions",

  "src.components.routes.pages.Gdpr.description47":
    "1. By submitting an order via the online order form, you confirm that you have been informed of the personal data protection terms and that you fully accept them.",
  "src.components.routes.pages.Gdpr.description48":
    "2. You agree to these terms by checking the consent box via the online form. By checking the consent box, you confirm that you have been informed of the personal data protection terms and that you fully accept them.",
  "src.components.routes.pages.Gdpr.description49":
    "The controller is entitled to change these conditions. The new version of the personal data protection terms will be published on the controller's website, or the new version of these terms will be sent to the email address you provided to the controller.",
  "src.components.routes.pages.Gdpr.description50":
    "We reserve the right to change this privacy statement in accordance with legal regulations. We will inform you of any significant changes, such as changes in purpose or new purposes for processing.",
  "src.components.organisms.forms.TermsForm.name": "Name and Surname",
  "src.components.organisms.forms.TermsForm.email": "Email",
  "src.components.organisms.forms.TermsForm.fakutra": "Invoice Number",
  "src.components.organisms.forms.TermsForm.comment": "Reason for Complaint",
  "src.components.organisms.forms.TermsForm.comment2":
    "Your Request for Resolution",
  "src.components.routes.pages.VyzkousejMenu.title": "TryMenu",
  "src.components.molecules.DeliveryCard.btn": "Show More",
  "src.components.atoms.KalorieItem.kalorie": "kcal",
  "src.components.organisms.OrderOnlineFirstStep.people":
    "I am ordering food for",
  "src.components.organisms.OrderOnlineFirstStep.menu": "Select Your Menu",
  "src.components.organisms.OrderOnlineFirstStep.kalorie":
    "Choose Your Caloric Intake {kkal}",
  "src.components.organisms.OrderOnlineFirstStep.kalorie.symbol": "(kcal)",
  "src.components.organisms.OrderOnlineFirstStep.program": "What is your goal?",
  "src.components.organisms.OrderOnlineFirstStep.duration": "Program Duration",
  "src.components.organisms.OrderOnlineFirstStep.duration.description":
    "How long do you want the food to be delivered?",
  "src.components.organisms.OrderOnlineFirstStep.dayWeek": "Week Length",
  "src.components.organisms.OrderOnlineFirstStep.dayWeek.description":
    "How many days a week will we deliver?",
  "src.components.molecules.OnlineMenuSlider.meat.title":
    "NutritionPro Classic",
  "src.components.molecules.OnlineMenuSlider.light.title": "NutritionPro Light",
  "src.components.molecules.OnlineMenuSlider.vege.title": "NutritionPro Vege",
  "src.components.molecules.OnlineMenuSlider.fish.title": "Menu without fish",
  "src.components.molecules.OnlineMenuSlider.combo.title": "NutritionPro combo",
  "src.components.molecules.OnlineProgramBlock.lose.title":
    "I want to lose weight",
  "src.components.molecules.OnlineProgramBlock.gain.title":
    "I want to gain muscle",
  "src.components.molecules.OnlineProgramBlock.maintanace.title":
    "I want to maintain my weight",
  "src.components.molecules.OnlineProgramBlock.lunch.title":
    "I don't want to cook at home",
  "src.components.molecules.ProgramWeekDuration.dni": " {day} days",
  "src.components.molecules.ProgramWeekDuration.info6": "(Monday to Saturday)",
  "src.components.molecules.ProgramWeekDuration.info5": "(Monday to Friday)",
  "src.components.molecules.ProgramWeekDuration.info4": "(Monday to Thursday)",

  "src.components.molecules.OnlinePersonCard.sebe": "Myself",
  "src.components.molecules.OnlinePersonCard.people": "2 people",
  "src.components.molecules.OnlinePersonCard.lidi": "5+ people",
  "src.components.molecules.OnlinePersonCard.tooltip":
    "For orders for more people, use the 'I'm Interested' form",

  "src.components.molecules.OnlinePersonCard.info":
    "*Discount applies only to the first order",

  "src.components.molecules.OrderRightCard.call": "I'm Interested",
  "src.components.molecules.OrderRightCard.nebo": "or",
  "src.components.templates.OnlineOrderMobileTemplate.close": "Cancel",
  "src.components.organisms.PromoCodeModal.title": "Promo Code",
  "src.components.organisms.PromoCodeModal.field": "Enter Promo Code",
  "src.components.organisms.forms.PromoCodeForm.btn": "Apply",

  "src.components.organisms.forms.PromoCodeForm.errorMessage":
    "This promo code is not valid",
  "src.components.molecules.OrderRightCard.call.title": "I'm Interested",
  "src.components.organisms.OnlineOrderSecondStep.info": "Personal Information",
  "src.components.organisms.OnlineOrderSecondStep.name": "Name and Surname",
  "src.components.organisms.OnlineOrderSecondStep.email": "Email",
  "src.components.organisms.OnlineOrderSecondStep.psc": "Postal Code",
  "src.components.organisms.OnlineOrderSecondStep.ico": "IČO ",
  "src.components.organisms.OnlineOrderSecondStep.dic": "DIČ",
  "src.components.organisms.OnlineOrderSecondStep.factura": "Billing Address",
  "src.components.organisms.OnlineOrderSecondStep.companyName": "Company Name",
  "src.components.organisms.OnlineOrderSecondStep.switchCompany":
    "Order for company",

  "src.components.organisms.OnlineOrderSecondStep.companyInfo":
    "Billing Information",

  "src.components.molecules.OrderSecondStepRightCard.title": "Order Price",
  "src.components.molecules.OnlineOrderAddressCheck.city": "City",
  "src.components.molecules.OnlineOrderAddressCheck.pickUpPoint":
    "Pick-up Point",

  "src.components.molecules.OnlineOrderAddressCheck.address": "Address",
  "src.components.organisms.OnlineOrderThiredStep.calendar.title":
    "Program Start Date",

  "src.components.organisms.OnlineOrderThiredStep.calendar.description":
    "Choose the date when you want to start receiving our boxes. We will deliver the first boxes the day before the selected date.",

  "src.components.organisms.OnlineOrderThiredStep.time.title":
    "Delivery Time Window",

  "src.components.organisms.OnlineOrderThiredStep.time.prague":
    "Choose the most suitable 2-hour delivery window. Our couriers deliver food boxes from 18:00 to 22:00.",
  "src.components.organisms.OnlineOrderThiredStep.time.prague-area":
    "Choose the most suitable 2-hour delivery window. Our couriers deliver food boxes from 18:00 to 22:00.",
  "src.components.organisms.OnlineOrderThiredStep.time.kladno":
    "Choose the most suitable 2-hour delivery window. Our couriers deliver food boxes from 18:00 to 22:00.",
  "src.components.organisms.OnlineOrderThiredStep.time.plzen":
    "Choose the most suitable 2-hour delivery window. Our couriers deliver food boxes from 17:00 to 21:00.",
  "src.components.organisms.OnlineOrderThiredStep.platba": "Choose Payment",
  "src.components.organisms.OnlineOrderThiredStep.platba.gopay":
    "Secure payment provided by",

  "src.components.molecules.PromoCodeApprove.promoCode": "Promo Code",
  "src.components.molecules.PromoCodeApprove.remove": "Remove",
  "src.components.molecules.OnlineOrderThiredStepSidebar.discount":
    "Volume Discount",

  "src.components.molecules.OnlineOrderThiredStepSidebar.confirm": "Order",
  "src.components.organisms.TimeSlotModal.title": "Delivery Time Window",
  "src.components.organisms.TimeSlotModal.field":
    "Choose the most suitable 2-hour window.",

  "src.components.organisms.TimeSlot.label": "Choose Delivery Time",
  "src.components.organisms.CalendarOrder.label": "Choose Date",
  "src.components.organisms.CalendarModal.title": "Program Start Date",
  "src.components.organisms.CalendarModal.btn": "Confirm Date",
  "src.components.organisms.OrderOnlineFirstStep.porcel.description":
    "How many meals will we deliver?",

  "src.components.organisms.OrderOnlineFirstStep.porcel": "Number of meals:",
  "src.components.organisms.HowCook.tab1.title": "Most Flexible Menu",
  "src.components.organisms.HowCook.tab2.title": "Highest Food Quality",
  "src.components.organisms.HowCook.tab3.title": "Most Accurate Delivery",
  "src.components.organisms.HowCook.tab1.title1":
    "Choose up to 4 meals per course",

  "src.components.molecules.PorcelBlock.menu": "course menu",
  "src.components.organisms.HowCook.tab1.descritpion1":
    "Do you crave a sweet or savory breakfast? Do you prefer a light salad over a hot dinner? It's up to you!",
  "src.components.organisms.HowCook.tab1.title2":
    "Customize your menu in the client zone",

  "src.components.organisms.HowCook.tab1.description2":
    "Easily adjust everything in our intuitive user interface, and see how changes affect the distribution of macronutrients.",
  "src.components.organisms.HowCook.tab1.title3":
    "Adjust intake according to yourself",
  "src.components.organisms.RateBlock.subTitle":
    "10 meals for 2 days with a discount of {greenTitle}",
  "src.components.organisms.BackBoxesRateBlock.subTitle1":
    "{boldTitle} on your next order!",

  "src.components.organisms.BackBoxesRateBlock.subTitleBold1": "15% Discount",
  "src.components.organisms.BackBoxesRateBlock.subTitle2":
    "Promo Code: {boldTitle}",

  "src.components.organisms.BackBoxesRateBlock.subTitleBold2": "ZPATKY1500",
  "src.components.organisms.HowCook.tab1.description3":
    "Calories can be freely chosen from 800-2600 kcal with an accuracy of 100 kcal and the number of courses from 2 to 5 with the option to purchase an extra portion.",
  "src.components.organisms.HowCook.tab1.title4":
    "Supplement your diet with extra vitamins",
  "src.components.organisms.HowCook.tab1.descripton4":
    "You can also buy vitamin packs in a convenient one-day package with your meal.",
  "src.components.molecules.OnlineOrderAddressCheck.tab1":
    "Delivery to Address",
  "src.components.molecules.OnlineOrderAddressCheck.tab2":
    "Delivery to Pick-up Point",
  "src.components.organisms.HowCook.tab2.title1":
    "Don't worry about preparation quality",
  "src.components.organisms.HowCook.tab2.descritpion1":
    "Food is prepared in the most modern kitchens under the control of our technologist and quality specialist, according to the highest hygiene standards.",
  "src.components.organisms.HowCook.tab2.title2": "Enjoy maximum freshness",
  "src.components.organisms.HowCook.tab2.description2":
    "Throughout the time from production to delivery, we measure the maintenance of the temperature chain for maximum quality and freshness of the food upon delivery.",
  "src.components.organisms.HowCook.tab2.title3": "Eat only the best calories",
  "src.components.organisms.HowCook.tab2.description3":
    "The composition of the meals is controlled by our nutrition specialist, and we make sure that the calories in the food come from the highest quality sources, without using artificial sweeteners.",
  "src.components.organisms.HowCook.tab2.title4": "Try a variety of meals",
  "src.components.organisms.HowCook.tab2.descripton4":
    "Our menu has something from every cuisine, from lasagna to butter chicken, but don't worry, if you don't like exotic food, you can always replace the meal, for example with a healthy goulash.",
  "src.components.organisms.HowCook.tab3.title1": "Choose when we will deliver",
  "src.components.organisms.HowCook.tab3.descritpion1":
    "In Prague, we deliver within 2-hour windows that you choose yourself, so you don't have to wait all day to see when the courier will arrive.",
  "src.components.organisms.HowCook.tab3.title2":
    "Flexibly change place and time",
  "src.components.organisms.HowCook.tab3.description2":
    "You can still change the delivery place and time on the same day or have it delivered to a pick-up point.",
  "src.components.organisms.HowCook.tab3.title3":
    "Use one of our pick-up points",
  "src.components.organisms.HowCook.tab3.description3":
    "You can pick up your order at one of our 10 pick-up points, conveniently located in high-traffic areas such as fitness centers.",
  "src.components.organisms.HowCook.tab3.title4":
    "Have it delivered to more than 10 cities in the Czech Republic",
  "src.components.organisms.HowCook.tab3.descripton4":
    "You can also change the delivery location between cities. So if you're on a business trip or visiting relatives, we'll deliver your boxes where you need them.",
  "src.components.moelecules.BenefitLabels.text1":
    "Choice of {head} for each course according to taste.",
  "src.components.moelecules.BenefitLabels.subText1": "4 meals",
  "src.components.moelecules.BenefitLabels.text2":
    "We deliver food in {head}, or to pick-up points.",
  "src.components.moelecules.BenefitLabels.subText2": "2-hour windows",
  "src.components.moelecules.BenefitLabels.text3":
    "Calories through {head}, not sauces.",
  "src.components.moelecules.BenefitLabels.subText3": "proteins",
  "src.components.moelecules.BenefitLabels.text4": "Pause {head}, just call.",
  "src.components.moelecules.BenefitLabels.subText4": "anytime for free",
  "src.components.molecules.InfoHeader.text":
    "{info} You can now choose up to 4 meal options per course in the client zone",
  "src.components.molecules.InfoHeader.info": "New!",
  "src.components.atoms.HowCookItem.info": "More info",
  "src.components.molecules.OnlineOrderSecondStepSidebar.terms1":
    "By continuing, you agree to the",
  "src.components.molecules.OnlineOrderSecondStepSidebar.terms1.link":
    "terms and conditions.",
  "src.components.molecules.OnlineOrderSecondStepSidebar.terms2":
    "How we handle your personal data can be found ",
  "src.components.molecules.OnlineOrderSecondStepSidebar.terms2.link": "here.",
  "src.components.molecules.OnlineOrderThiredStepSidebar.priceDay":
    "Price per menu (excluding delivery):",
  "src.components.molecules.OnlineOrderThiredStepSidebar.priceTotal":
    "Price for the entire period:",
  "src.components.molecules.OnlineOrderThiredStepSidebar.priceDelivery":
    "Delivery price per day:",
  "src.components.routes.pages.PaymentNotCompleted.title":
    "Payment not complited",
  "src.components.routes.pages.ThankYouOrder.title": "Order complited",
  "src.components.organisms.PaymentModal.succsesTitle":
    "Děkujeme, vaši objednávku jsme úspěšně přijali.",
  "src.components.organisms.PaymentModal.succsesText":
    "Mezitím jsme vám do e-mailu poslali veškeré informace k dokončení platby bankovním převodem.",
  "src.components.organisms.Faq.menu.content1.bold": "meal box",
  "src.components.organisms.Faq.menu.content2.bold": " meal box",
  "src.components.organisms.Faq.menu.content3.bold": "meal box",
  "src.components.organisms.Faq.menu.content3.boldInfo": "ukázku jídelníčku",
  "src.components.organisms.Faq.materials.content1.bold": "meal box delivery ",
  "src.components.organisms.Faq.materials.content2.bold": "meal box delivery ",
  "src.components.organisms.Faq.materials.content3.bold": "meal box delivery ",
  "src.components.organisms.Faq.materials.content4.bold": "meal box delivery ",
  "src.components.organisms.Faq.delivery.content1.bold": "meal box delivery ",
  "src.components.organisms.Faq.delivery.content2.bold": "meal box delivery ",
  "src.components.organisms.Faq.delivery.content3.bold": "meal box",
  "src.components.organisms.Faq.delivery.content3.boldInfo":
    "online kalkulačce",
  "src.components.organisms.Faq.delivery.content4.bold": "meal box delivery ",
  "src.components.organisms.Faq.delivery.content6.bold": "meal box delivery ",
  "src.components.organisms.Faq.delivery.content7.bold": "meal box delivery ",
  "src.components.organisms.Faq.delivery.content9.bold": "meal box delivery ",
  "src.components.organisms.Faq.consumption.content1.bold":
    "meal box delivery ",
  "src.components.organisms.Faq.consumption.content2.bold":
    "meal box delivery ",
  "src.components.organisms.Faq.consumption.content3.bold":
    "meal box delivery ",
  "src.components.organisms.Faq.client.subTitle1":
    "Can I change my meal plan if my needs change?",
  "src.components.organisms.Faq.client.content1":
    "Yes, our meal box service is very flexible. If you need to change your meal plan or adjust portion sizes, you can easily do so through our customer portal or by contacting our customer support.",
  "src.components.organisms.Faq.client.subTitle2":
    "What customization options do you offer for your meal box delivery?",
  "src.components.organisms.Faq.client.content2":
    "We allow you to customize your menu according to your preferences, allergies, and other requirements. You can choose the number of courses, omit certain ingredients, or adjust portion sizes to suit your needs.",
  "src.components.organisms.Faq.client.subTitle3":
    "How can I take a break from the meal box service?",
  "src.components.organisms.Faq.client.content3":
    "You can take a break at any time without any cost. Simply call {info} or email our customer service at info@nutritionpro.cz.",
  "src.components.organisms.Faq.client.content3.bold": "226 288 200",
  "src.components.organisms.Faq.client.subTitle4":
    "How can I contact customer support if I have a problem or question?",
  "src.components.organisms.Faq.client.content4":
    "Our customer support is available every weekday from 08:00 to 18:00. You can reach us by phone at  {info}, by email at info@nutritionpro.cz, or through our customer portal, where we strive to respond as quickly as possible.",
  "src.components.organisms.Faq.client.content4.bold": "226 288 200",
  "src.components.organisms.Faq.client.subTitle5":
    "How do you handle feedback and complaints?",
  "src.components.organisms.Faq.client.content5":
    "Your satisfaction is our priority. We address each piece of feedback or complaint individually and strive to find a solution that meets your needs.",
  "src.components.organisms.Faq.delivery.prague": "celé Praze a okolí",
  "src.components.organisms.Faq.delivery.chech": "středních Čechách",
  "src.components.organisms.Faq.delivery.kladno": "Kladně",
  "src.components.organisms.Faq.delivery.plzen": "Plzni",
  "src.components.organisms.Faq.delivery.liberec": "Liberci",
  "src.components.organisms.Faq.delivery.mlda": "Mladé Boleslavi",
  "src.components.organisms.Faq.delivery.jablonec": "Jablonci nad Nisou",
  "src.components.organisms.Faq.delivery.paradubice": "Pardubicích",
  "src.components.organisms.Faq.delivery.hradec": "Hradci Králové",
  "src.components.organisms.OnlineOrderThiredStep.emptyTimeSlot":
    "Jídlo doručujeme od 16:00 do 19:00 v úterý, čtvrtek a neděli.",

  "src.components.organisms.FaqPraha.menu.content1.bold":
    "krabičková dieta Praha",
  "src.components.organisms.FaqPraha.menu.content1.boldInfo":
    "krabičková dieta",
  "src.components.organisms.FaqPraha.menu.content1":
    "Naše {info} se vyznačuje individuálním přístupem, protože věříme, že každý člověk je jedinečný a potřebuje specifický kalorický příjem, aby dosáhl svých cílů zdravou cestou. Menu pro 70kg ženu, která chce zhubnout, bude vypadat úplně jinak než pro 90kg muže, který se snaží nabrat svalovou hmotu. Naše {boldInfo} je navržena tak, aby odpovídala vašim individuálním potřebám a cílům, což je něco, co běžné krabičkové diety často opomíjejí.",
  "src.components.organisms.FaqPraha.menu.content2":
    "Jídelníček pro naši {info} je sestavován speciálním algoritmem, který pečlivě zohledňuje potřeby vašeho těla a vaše cíle. Naše odbornice na výživu, Jitka Dokoupilová, dohlíží na složení jednotlivých jídel a zajišťuje, že dosáhnete požadovaných výsledků.",
  "src.components.organisms.FaqPraha.menu.content2.bold":
    "krabičkovou dietu Praha",
  "src.components.organisms.FaqPraha.menu.content3.boldInfo":
    "krabičkovou dietu Praha.",
  "src.components.organisms.FaqPraha.menu.content3.bold": "krabičková dieta",
  "src.components.organisms.FaqPraha.menu.content3":
    "Naše {info} je založena na zásadách vyvážené stravy. Jídelníček je pestrý a chutný, s více než 500 různými jídly, která se pravidelně obměňují. Neustále aktualizujeme naši databázi jídel, abychom vám mohli nabídnout nové a zajímavé možnosti, takže se naše jídla nikdy nepřejí. Podívejte se na ukázku jídelníčku, který nabízíme pro {boldInfo}",
  "src.components.organisms.FaqPraha.materials.content1":
    "Pro naši {info} používáme pouze kvalitní a čerstvé suroviny od prověřených českých dodavatelů. Kvalita a čerstvost surovin je pro nás základem zdravého stravování.",
  "src.components.organisms.FaqPraha.materials.content1.bold":
    "krabičkovou dietu Praha",
  "src.components.organisms.FaqPraha.materials.content2":
    "Ano, všechna jídla v rámci naší {info} jsou připravována z čerstvých surovin, které kupujeme v den přípravy. Po uvaření jsou jídla šokově zchlazena a hermeticky zabalena, což zajišťuje jejich maximální čerstvost.",
  "src.components.organisms.FaqPraha.materials.content2.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.materials.content3":
    "Příprava jídel pro naši {info} probíhá v den rozvozu. Jídla připravujeme v neděli na pondělí a úterý, v úterý na středu a čtvrtek, a ve čtvrtek na pátek a sobotu.",
  "src.components.organisms.FaqPraha.materials.content3.bold":
    "krabičkovou dietu",
  "src.components.organisms.FaqPraha.materials.content4":
    "Ano, naše {info} zahrnuje i výborné dezerty, které jsou přesně spočítány tak, aby zapadly do vašeho osobního jídelníčku.",
  "src.components.organisms.FaqPraha.materials.content4.bold":
    "krabičková dieta Praha",
  "src.components.organisms.FaqPraha.delivery.content1":
    "Jakékoliv změny objednávky v rámci naší {info}, včetně pozastavení dovozu, je nutné nahlásit nejpozději do 11:00 tři pracovní dny před plánovaným doručením. Tato pravidla platí, protože pro každé vaření nakupujeme čerstvé suroviny na přesný počet zákazníků.",
  "src.components.organisms.FaqPraha.delivery.content1.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.delivery.content2.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.delivery.content2":
    "Pokud se vaše objednávka {info} vztahuje na dva dny, je možné zrušit pouze celou dvoudenní objednávku. Zrušení pouze jednoho dne není možné.",
  "src.components.organisms.FaqPraha.delivery.content3.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.delivery.content3":
    "Cena naší {info} je stanovena individuálně podle vašeho osobního stravovacího plánu a denního kalorického příjmu. Cena se může lišit v závislosti na vašich cílech, ať už se jedná o hubnutí, nabírání svalové hmoty nebo udržování váhy. Kalkulaci ceny si můžete nechat vypočítat v naší online kalkulačce.",
  "src.components.organisms.FaqPraha.delivery.content4":
    "Po dokončení objednávky naší {info} vám zašleme fakturu na váš e-mail. Můžete také zvolit platbu kartou.",
  "src.components.organisms.FaqPraha.delivery.content4.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.delivery.content6":
    "Naši {info} rozvážíme po {prague}, {chech} , {kladno}, {plzen}, {liberec}, {mlda}, {jablonec}, {paradubic} a {hradec}.",
  "src.components.organisms.FaqPraha.delivery.content6.bold":
    "krabičkovou dietu Praha",
  "src.components.organisms.FaqPraha.delivery.content7":
    "Jídla z naší {info} doručujeme obden – v úterý, čtvrtek a neděli, vždy mezi 16:00 a 22:00 hodinou.",
  "src.components.organisms.FaqPraha.delivery.content7.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.delivery.content8":
    "Můžete si vybrat konkrétní 2hodinové okno pro doručení (17:00 - 22:00). Přesný čas dovozu bude upřesněn s řidičem v den dovozu.",
  "src.components.organisms.FaqPraha.delivery.content9":
    "Jsme flexibilní a rozumíme, že plány se mohou změnit. Čas či místo doručení naší {info} můžete změnit ještě v ten samý den do 12:00.",
  "src.components.organisms.FaqPraha.delivery.content9.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.consumption.content1":
    "Některá jídla z naší {info} jsou určena k ohřevu, jiná ke konzumaci za studena. Pro lepší chuť však doporučujeme jídla ohřát. Informace o tom, které jídlo je třeba ohřát, zjistíte po načtení čárového kódu na krabičce.",
  "src.components.organisms.FaqPraha.consumption.content1.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.consumption.content2.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.consumption.content2":
    "Pokud ohříváte jídla z naší {info} v mikrovlnné troubě, doporučujeme propíchnout dírky do folie. Čas ohřevu se liší podle druhu jídla a velikosti porce, ale doporučujeme použít střední výkon (600–700 W) pro rovnoměrné prohřátí.",
  "src.components.organisms.FaqPraha.consumption.content3":
    "Jídla z naší {info} doporučujeme skladovat v lednici, ale můžete je vzít s sebou i do práce nebo na cesty. Jídla vydrží několik hodin mimo lednici bez ztráty chuti nebo kvality.",
  "src.components.organisms.FaqPraha.consumption.content3.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.client.content1":
    "Ano, naše {info} je velmi flexibilní. Pokud potřebujete změnit své jídelníčky nebo upravit velikost porcí, můžete to snadno provést přes náš zákaznický portál nebo kontaktováním naší zákaznické podpory.",
  "src.components.organisms.FaqPraha.client.content1.bold":
    "krabičková dieta Praha",
  "src.components.organisms.FaqPraha.client.content2.bold":
    "krabičkové diety Praha",
  "src.components.organisms.FaqPraha.client.content2":
    "Umožňujeme vám přizpůsobit si menu podle vašich preferencí, alergií a dalších požadavků. Můžete si zvolit počet chodů, vynechat určité ingredience nebo upravit porce dle vaší potřeby v rámci naší {info}.",
  "src.components.organisms.FaqPraha.client.content3":
    "Pauzu si můžete dát kdykoliv, nestojí to nic, stačí jen zavolat na {info} nebo napsat e-mail na náš zákaznický servis {boldInfo}.",
  "src.components.organisms.FaqPraha.client.content3.bold": " 226 288 200",
  "src.components.organisms.FaqPraha.client.content3.boldInfo":
    "info@nutritionpro.cz.",
  "src.components.organisms.FaqPraha.client.content4":
    "Naše zákaznická podpora je vám k dispozici každý pracovní den od 08:00-18:00. Můžete nás kontaktovat telefonicky {info}, e-mailem {boldInfo}, nebo prostřednictvím našeho zákaznického portálu, kde se snažíme odpovědět co",
  "src.components.organisms.FaqPraha.client.content4.bold": "226 288 200",
  "src.components.organisms.FaqPraha.client.content4.boldInfo":
    "info@nutritionpro.cz",
  "src.components.moelecules.BenefitLabels.text3.praha":
    "Kalorie přes {head}, ne omáčky.",
  "src.components.moelecules.BenefitLabels.text4.praha":
    "Pauza {head}, stačí zavolat.",
  "src.components.moelecules.BenefitLabels.text1.praha":
    "Výběr ze {head} u každého chodu podle chuti",
  "src.components.moelecules.BenefitLabels.text2.praha":
    "Rozvážíme krabickou dietu v Praze ve {head}, nebo na odběrné místa.",

  "src.components.organisms.ProgramBlock.titleSecond.praha":
    " jeden z našich programů",
  "src.components.organisms.ProgramBlock.description.praha":
    "Užij si pestrou chuťovou paletu bez omezení. V rámci všech programů máš nyní možnost libovolné chody vyměnit.",
  "src.components.organisms.MenuBlock.titleFirst.praha": "Jaká jídla",
  "src.components.organisms.MenuBlock.titleSecond.praha": " tě s námi čekají?",
  "src.components.organisms.ComplateMenu.titleFirst.praha":
    "Nezávazně vyzkoušej naši",
  "src.components.organisms.ComplateMenu.titleSecond.praha":
    "naši krabičkovou dietu na 2 dny",
  "src.components.organisms.ComplateMenu.description.praha":
    "Leave us your contact, and we will call you within two hours during business hours to arrange everything within 10 minutes.",
  "src.components.organisms.ReviewBlock.description.praha":
    " Ke každému zákazníkovi přistupujeme individuálně, abychom zajistili nejlepší možné služby. | Krabičková dieta Praha",
  "src.components.organisms.HowCook.titleFirst.praha": "Proč NutritionPro - ",
  "src.components.moelecules.BenefitCityLabels.text3":
    "Calories through proteins, not sauces.",
  "src.components.moelecules.BenefitCityLabels.text4":
    "Pause anytime for free, just give us a call.",
  "src.components.moelecules.BenefitCityLabels.text1":
    "Choose from {head} for each course according to your taste.",
  "src.components.moelecules.BenefitCityLabels.subText2": "4 meals",
  "src.components.moelecules.BenefitCityLabels.text2":
    "Deliveries in the morning and evening, to your home or to a pick-up point.",
  "src.components.organisms.FaqPraha.titleFirst": "Často ",
  "src.components.organisms.FaqPraha.titleSecond": "kladené dotazy",
  "src.components.organisms.HowWorks.daily.praha":
    "Nastavíme tvůj denní příjem podle tvých cílů",
  "src.components.organisms.HowWorks.algorithm.praha":
    "Speciální algoritmus sestaví jídelníček na míru",
  "src.components.organisms.HowWorks.cooking.praha": "Jídlo uvaříme",
  "src.components.organisms.HowWorks.delivery.praha":
    "Dovezeme ti domů či do práce",
  "src.components.organisms.HowWorks.goal.praha":
    " Následně dohlédneme na to, abys svého cíle dosáhl | Krabičková dieta Praha",
  "src.components.organisms.HowWorks.titleFirst.praha": "Jak funguje naše ",
  "src.components.organisms.HowWorks.titleSecond.praha":
    "krabičková dieta v Praze?",

  "src.components.organisms.HowCook.tab3.descripton4.praha":
    "Zároveň si můžeš mezi městy i měnit místo dovozu, tedy pokud tě čeká pracovní cesta, či návštěva příbuzných, krabičky ti doručíme kam bude potřeba.",
  "src.components.organisms.HowCook.tab3.description3.praha":
    "Odebírat můžeš na jednom z našich 10 odběrných míst, pohodlně umístěných na frekventovaných místech jako jsou fitness centra. | Krabičková dieta Praha",
  "src.components.organisms.HowCook.tab3.description2.praha":
    "Místo i čas dovozu si ještě ten samý den můžeš změnit, případně si nechat dovézt na odběrné místo. | Krabičková dieta Praha",
  "src.components.organisms.HowCook.tab3.descritpion1.praha":
    "V Praze vozíme v 2 hodinových oknech, které si sám navolíš, tedy nemusíš každý den čekat, jak to zrovna vyjde kurýrovi.",
  "src.components.organisms.HowCook.tab2.descripton4.praha":
    " V jídelníčku máme z každé kuchyně něco, od lasagních po butter chicken, ale neboj, pokud ti exotika nevyhovuje, vždy si můžeš jídlo nahradit, třeba zdravým gulášem. | Krabičková dieta Praha",
  "src.components.organisms.HowCook.tab2.description3.praha":
    "Složení jídel je kontrolováno naší nutriční specialistkou a dbáme na to, aby kalorie v jídle pocházeli z těch nejkvalitnějších zdrojů, bez použití umělých sladidel.",
  "src.components.organisms.HowCook.tab2.description2.praha":
    "Po celou dobu od výroby po doručení měříme zachování teplotního řetězce pro maximální kvalitu a čerstvost jídla při dodání.",
  "src.components.organisms.HowCook.tab2.descritpion1.praha":
    " Krabickovu Diety Praha připravujeme v nejmodernějších kuchyních pod kontrolou našeho technologa a kvalitáře, dle nejvyšších hygienických standardů.",
  "src.components.organisms.HowCook.tab1.descripton4.praha":
    "K jídlu si můžeš také dokoupit vitamínové balíčky v pohodlném jednodenním balení.",
  "src.components.organisms.HowCook.tab1.description3.praha":
    "Kalorie si můžeš libovolně volit od 800-2600 kcal s přesností na 100 kcal a počet chodů od 2 do 5 s možností dokoupit extra porci.",
  "src.components.organisms.HowCook.tab1.description2.praha":
    "Vše si navolíš jednoduše v našem intuitivním uživatelském prostředí, kde zároveň vidíš i jak změna ovlivní rozložení makronutrientů.",
  "src.components.organisms.HowCook.tab1.descritpion1.praha":
    "Máš chuť na sladkou, nebo slanou snídani? Dáš přednost lehkému salátu před teplou večeří? U nás je to na tobě!",

  "src.components.organisms.HowCook.tab1.title1.praha":
    "Vybírej až ze 4 jídel na každý chod",
  "src.components.organisms.HowCook.tab1.title2.praha":
    "Navol si jídelníček v klientské zóně",

  "src.components.organisms.HowCook.tab1.title3.praha":
    "Uzpůsob si příjem dle sebe",
  "src.components.organisms.HowCook.tab1.title4.praha":
    " Doplň si Krabickovu Diety Praha o extra vitamíny",

  "src.components.organisms.HowCook.tab2.title1.praha":
    "Neboj se o kvalitu přípravy",

  "src.components.organisms.HowCook.tab2.title2.praha":
    "Užij si maximální čerstvost",
  "src.components.organisms.HowCook.tab2.title3.praha":
    "Jez jen ty nejlepší kalorie",
  "src.components.organisms.HowCook.tab2.title4.praha":
    "Vyzkoušej rozmanitost jídel",
  "src.components.organisms.HowCook.tab3.title1.praha":
    "Krabičková dieta Praha - Vyber si kdy ti budeme vozit",

  "src.components.organisms.HowCook.tab3.title2.praha":
    "Krabičková dieta Praha - Flexibilně si změň místo a čas",
  "src.components.organisms.HowCook.tab3.title3.praha":
    "Krabičková dieta Praha - Využij jedno z našich odběrných míst",

  "src.components.organisms.HowCook.tab3.title4.praha":
    "Krabičková dieta Praha - Nech si přivézt do více než 10 měst v ČR",
  "src.components.molecules.FirstStepTabs.tab1": "Standard program",
  "src.components.molecules.FirstStepTabs.tab2": "Demo program",
  "src.components.molecules.OnlineOrderThiredStepSidebar.priceDayDemo":
    "Total price",
  "src.components.molecules.OnlineConfirmCard.priceDemo": "{price} Kč",
  "src.components.atoms.PricePerDay.titleDemo": "Total price",
  "src.components.organisms.ComplateMenu.redBtn":
    "Objednat demo program online",

  "src.components.templates.OnlineOrderMobileTemplate.title1":
    "Calculate the price",
  "src.components.templates.OnlineOrderMobileTemplate.title2":
    "Contact Details",
  "src.components.templates.OnlineOrderMobileTemplate.title3":
    "Doručení a platba",
  "src.components.templates.OnlineOrderMobileTemplate.description1":
    "There are just a few steps left to deliver the meal boxes.",
  "src.components.templates.OnlineOrderMobileTemplate.description2":
    "Please proceed by filling in your contact information.",
  "src.components.templates.OnlineOrderMobileTemplate.description3":
    "Dokončete objednávku vyplněním času rozvozu a výběrem platby.",
  "src.components.templates.OnlineOrderMobileTemplate.back": "Back",
  "src.components.templates.OnlineOrderDesctopTemplate.sidebarTitile":
    "Order summary",
  "src.components.molecules.Steps.firstStep": "Select program",
  "src.components.molecules.Steps.secondStep": "Contact Details",
  "src.components.molecules.Steps.lastStep": "Delivery and payment",
  "src.components.moelcules.OnlineOrderAddressCheck.city": "Enter City",
  "src.components.moelcules.OnlineOrderAddressCheck.pick":
    "Select a Pickup Location",

  "src.component.atoms.AddressInput.success": "Free shipping",
  "src.component.atoms.AddressInput.error":
    "We are sorry! We are not in this location yet.",
  "src.component.atoms.AddressInput.placeHolder": "Enter street and apt.numb",
  "src.component.atoms.AddressInput.button": "Confirm",
  "src.components.molecules.FixedMark.rate": "  rating",
  "src.component.molecules.ProgramDurationBlock.default": "Best",
  "src.component.molecules.ProgramDurationBlock.demo": "Discount 50%",
  "src.component.molecules.ProgramDurationBlock.info": "Payment: Monthly",
  "src.component.molecules.ProgramDurationBlock.price": "CZK",
  "src.component.molecules.ProgramDurationBlock.day": "day",

  "src.components.organisms.PickUpPointsModal.text": "Collection points",

  "src.components.organisms.HomePgaeSliderDefault.title": "500CZK DISCOUNT",
  "src.components.organisms.HomePgaeSliderDefault.promoCode":
    "PROMOCODE DIETA500",
  "src.components.organisms.HomePgaeSliderDefault.priceBtn": "Order Online Now",
  "src.components.organisms.HomePgaeSliderDefault.description":
    "Finally, a meal box service that’s truly worth it!",
  "src.components.orhanisms.HomePageSliderDemo.btn": "Order Online Now",
  "src.components.orhanisms.HomePageSliderDemo.title":
    "Get a 2 day trial menu with a 50% discount!",
  "src.components.orhanisms.HomePageSliderDemo.priceText":
    " 2-day menu with 5 courses daily for only ",
  "src.components.orhanisms.HomePageSliderDemo.price": "699 CZK!",
  "src.components.orhanisms.HomePageSliderDemo.description":
    " Limited availability - try it now!",
  "src.component.molecules.ProgramDurationBlock.days": "days",
  "src.components.molecules.FixedMark.rated": "Top-rated",
  "src.components.molecules.FixedMark.boxes": "meal box service",
  "src.components.molecules.FixedMark.count": "positive customer reviews",
};
